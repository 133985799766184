import { ThunkAction } from 'redux-thunk'
import * as companyService from "../../../services/companiesService";
import { CompanyEmployee } from "../../models/Company";
import { actionCreators as sharedActions, TApiCallAction } from "../../shared/actions";
import { ApplicationState } from "../../";

// action types
export enum CompanyEmployeeActionTypes {
    COMPANYEMPLOYEES_LOAD_SUCCESS = "COMPANYEMPLOYEES_LOAD_SUCCESS",
    COMPANYEMPLOYEES_CREATE_SUCCESS = "COMPANYEMPLOYEES_CREATE_SUCCESS",
    COMPANYEMPLOYEES_DELETE_SUCCESS = "COMPANYEMPLOYEES_DELETE_SUCCESS"
}
  
export interface ICompanyEmployeeAction {
    type: CompanyEmployeeActionTypes;
  }

export interface ICompanyEmployeeLoadSuccess extends ICompanyEmployeeAction {
    type: CompanyEmployeeActionTypes.COMPANYEMPLOYEES_LOAD_SUCCESS;
    companyId: string;
    companyEmployees:Array<CompanyEmployee>;
}

export interface ICreateCompanyEmployeesSuccessAction {
    type: CompanyEmployeeActionTypes.COMPANYEMPLOYEES_CREATE_SUCCESS;
    companyId: string;
    companyEmployees:Array<CompanyEmployee>;
}

export interface IDeleteCompanyEmployeesSuccessAction {
    type: CompanyEmployeeActionTypes.COMPANYEMPLOYEES_DELETE_SUCCESS;
    companyId: string;
    companyEmployeesIds: string[];
}

function loadSuccess(companyId: string, companyEmployees:Array<CompanyEmployee>): ICompanyEmployeeLoadSuccess {
    return {
        type: CompanyEmployeeActionTypes.COMPANYEMPLOYEES_LOAD_SUCCESS,
        companyId,
        companyEmployees
    }
}

function createSuccess(companyId: string, companyEmployees:Array<CompanyEmployee>): ICreateCompanyEmployeesSuccessAction {
    return {
        type: CompanyEmployeeActionTypes.COMPANYEMPLOYEES_CREATE_SUCCESS,
        companyId,
        companyEmployees
    }
}

function deleteSuccess(companyId: string,  companyEmployeesIds:string[]): IDeleteCompanyEmployeesSuccessAction {
    return {
        type: CompanyEmployeeActionTypes.COMPANYEMPLOYEES_DELETE_SUCCESS,
        companyId,
        companyEmployeesIds
    }
}

export type TCompanyEmployeeAction = ICompanyEmployeeLoadSuccess | ICreateCompanyEmployeesSuccessAction | IDeleteCompanyEmployeesSuccessAction;

export const actionCreators = {
    load: (companyId:string,filter?:string, orderby?:string): ThunkAction<Promise<void>, ApplicationState, {}, TCompanyEmployeeAction | TApiCallAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(companyId,[]));

        if(companyId){
            dispatch(sharedActions.beginApiCall());
            let accountId = getState().user.accountId;

            return await companyService.getCompanyEmployees(accountId, companyId, filter, orderby)
            .then(data => {
                dispatch(loadSuccess(companyId, data.items));
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
                });
        }
    },
    save: (companyId:string, employeeids:string[]) : ThunkAction<Promise<void>, ApplicationState, {}, TCompanyEmployeeAction | TApiCallAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());       
        let accountId = getState().user.accountId;

        return await companyService.addCompanyEmployees(accountId, companyId, employeeids)
        .then(data => {
            let employeeIds:Array<string> = data.employeeIds || [];

            let members =  getState().employees.employees.filter(x => employeeIds.includes(x.id)).map(x => ({
                employeeId: x.id,
                companyId: data.companyId
              }));

            dispatch(createSuccess(data.companyId, members));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
        
    },
    delete: (companyId:string, employeeids:string[]) : ThunkAction<Promise<void>, ApplicationState, {}, TCompanyEmployeeAction | TApiCallAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        
        return await companyService.deleteCompanyEmployees(accountId, companyId, employeeids)
        .then(data => {
            let employeeIds:Array<string> = data.employeeIds || [];
            dispatch(deleteSuccess(data.companyId,employeeIds));
            return;
        }).catch(error => {
        dispatch(sharedActions.apiCallError(error));
            throw error;
        });

    }    
}