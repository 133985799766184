import { Contact } from "../models/Contact";

export interface IContactsState {
    total:number;
    contacts: Array<Contact>;
}

export const initialContactsState  = {
    total:0,
    contacts:[]
}