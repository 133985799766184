import { store, history } from "../App";
import AppRoutes from "../routeConstants";

export function getUser() {
  const state = store.getState();
  
  if(state.appSettings.userManager){
    return state.appSettings.userManager.getUser();
  }
  throw new Error("userManager not initialized");
  
}

export function login() {
  const state = store.getState();
  if(state.appSettings.userManager){
    //return state.appSettings.userManager.signinRedirect({acr_values: "tenant:BEAE2309-45D2-406D-BD4E-C1B90E3A5A36"});
    return state.appSettings.userManager.signinRedirect();
  }
  throw new Error("userManager not initialized");  
}

export function renewToken() {
  const state = store.getState();
  
    if(state.appSettings.userManager){
      return state.appSettings.userManager.signinSilent();
    }
  
  throw new Error("userManager not initialized");    
}

export function logout() {
  const state = store.getState();
  if(state.appSettings.userManager){
    return state.appSettings.userManager.signoutRedirect();
  }
  throw new Error("userManager not initialized");   
}

export function signinRedirectCallback() {
  const state = store.getState();
  if(state.appSettings.userManager){
    state.appSettings.userManager.signinRedirectCallback().then(() => {
      history.push(AppRoutes.ROOT);
    });
  }else{
    throw new Error("userManager not initialized");   
  };
};