import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Theme,
  Hidden
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { actionCreators as userActions } from "../../store/user/actions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme:Theme) => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  appBarSpacer: theme.mixins.toolbar,
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));

interface NavMenuSecureProps {
  className?:string;
  logout?: () => void;
  onOpenNavBarMobile: () => void;
}

const NavMenuSecure: React.FunctionComponent<NavMenuSecureProps> = props => {
  let classes = useStyles();

  return (
    <>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
          <Hidden lgUp>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={props.onOpenNavBarMobile}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h4" className={classes.title}>
            Sharphours
          </Typography>
          {/* <Button color="inherit" onClick={props.logout}>Logout</Button> */}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default connect(null,
  {
    logout: userActions.logout
  }
)(NavMenuSecure);
