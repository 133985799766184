import { store } from "../App";
import { handleResponse, handleError, defaultSecureHeaders, getFilterAndSort } from "./apiUtils";
import { PagedResults } from "../store/models/PagedResults";
import { Image } from "../store/models/Image";


export async function getByProject(accountid:string, projectid:string, filter?:string, orderby?:string):Promise<PagedResults<Image>> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + "/projects/" + projectid +"/images" + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}
