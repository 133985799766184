import { ThunkAction } from 'redux-thunk';
import i18n from "../../i18n";
import * as timeEntriesService from "../../services/timeEntriesService";
import { TimeEntry, TimeEntrySummaryItem, TimeEntryStatus } from "../models/TimeEntry";
import { actionCreators as sharedActions, TApiCallAction } from "../shared/actions";
import { ApplicationState } from "../";
import { UpdateStatusResult } from '../../services/timeEntriesService';
import { StringifyOptions } from 'querystring';

// action types
export enum TimeEntryActionTypes {
    TIMEENTRIES_LOAD_SUCCESS = "TIMEENTRIES_LOAD_SUCCESS",
    TIMEENTRIES_DOWNLOAD_SUCCESS = "TIMEENTRIES_DOWNLOAD_SUCCESS",
    TIMEENTRIES_SUMMARY_LOAD_SUCCESS = "TIMEENTRIES_SUMMARY_LOAD_SUCCESS",
    TIMEENTRY_CREATE_SUCCESS = "TIMEENTRY_CREATE_SUCCESS",
    TIMEENTRY_UPDATE_SUCCESS = "TIMEENTRY_UPDATE_SUCCESS",
    TIMEENTRY_DELETE_SUCCESS = "TIMEENTRIES_DELETE_SUCCESS",
    TIMEENTRY_COPY_SUCCESS = "TIMEENTRIES_COPY_SUCCESS",
    TIMEENTRIES_UPDATE_STATUS_SUCCESS = "TIMEENTRIES_UPDATE_STATUS_SUCCESS"
}
  
export interface ITimeEntryAction {
    type: TimeEntryActionTypes;
  }

export interface ITimeEntryLoadSuccess extends ITimeEntryAction {
    type: TimeEntryActionTypes.TIMEENTRIES_LOAD_SUCCESS;
    total: number;
    timeentries:Array<TimeEntry>;
    filter: any;
    daterange: any;
}

export interface ITimeEntryDownLoadSuccess extends ITimeEntryAction {
    type: TimeEntryActionTypes.TIMEENTRIES_DOWNLOAD_SUCCESS;
}

export interface ITimeEntrySummaryLoadSuccess extends ITimeEntryAction {
    type: TimeEntryActionTypes.TIMEENTRIES_SUMMARY_LOAD_SUCCESS;
    items:TimeEntrySummaryItem[];
}

export interface ICreateTimeEntrySuccessAction {
    type: TimeEntryActionTypes.TIMEENTRY_CREATE_SUCCESS;
    timeentry: TimeEntry;
}
  
export interface IUpdateTimeEntrySuccessAction {
    type: TimeEntryActionTypes.TIMEENTRY_UPDATE_SUCCESS;
    timeentry: TimeEntry;
}

export interface IDeleteTimeEntrySuccessAction {
    type: TimeEntryActionTypes.TIMEENTRY_DELETE_SUCCESS;
    timeentryids: string[];
}

export interface ICopyTimeEntrySuccessAction {
    type: TimeEntryActionTypes.TIMEENTRY_COPY_SUCCESS;
    timeentries: TimeEntry[];
}

export interface IUpdateStatusTimeEntrySuccess {
    type: TimeEntryActionTypes.TIMEENTRIES_UPDATE_STATUS_SUCCESS;
    updateStatusResult:UpdateStatusResult[];
}

function loadSuccess(total: number, timeentries:Array<TimeEntry>, filter:any,daterange:any): ITimeEntryLoadSuccess {
    return {
        type: TimeEntryActionTypes.TIMEENTRIES_LOAD_SUCCESS,
        total,
        timeentries,
        filter,
        daterange
    }
}

function downloadSuccess(): ITimeEntryDownLoadSuccess {
    return {
        type: TimeEntryActionTypes.TIMEENTRIES_DOWNLOAD_SUCCESS
    }
}

function createSuccess(timeentry:TimeEntry): ICreateTimeEntrySuccessAction {
    return {
        type: TimeEntryActionTypes.TIMEENTRY_CREATE_SUCCESS,
        timeentry
    }
}

function updateSuccess(timeentry:TimeEntry): IUpdateTimeEntrySuccessAction {
    return {
        type: TimeEntryActionTypes.TIMEENTRY_UPDATE_SUCCESS,
        timeentry
    }
}

function deleteSuccess(timeentryids:string[]): IDeleteTimeEntrySuccessAction {
    return {
        type: TimeEntryActionTypes.TIMEENTRY_DELETE_SUCCESS,
        timeentryids
    }
}

function copySuccess(timeentries:TimeEntry[]): ICopyTimeEntrySuccessAction {
    return {
        type: TimeEntryActionTypes.TIMEENTRY_COPY_SUCCESS,
        timeentries
    }
}

function updateStatusSuccess(updateStatusResult:UpdateStatusResult[]): IUpdateStatusTimeEntrySuccess {
    return {
        type: TimeEntryActionTypes.TIMEENTRIES_UPDATE_STATUS_SUCCESS,
        updateStatusResult
    }
}

export function loadSummarySuccess(items:TimeEntrySummaryItem[]): ITimeEntrySummaryLoadSuccess {
    return {
        type: TimeEntryActionTypes.TIMEENTRIES_SUMMARY_LOAD_SUCCESS,
        items: items
    }
}

export type TTimeEntryAction = ITimeEntryLoadSuccess | ITimeEntryDownLoadSuccess |ITimeEntrySummaryLoadSuccess | 
    ICreateTimeEntrySuccessAction | IUpdateTimeEntrySuccessAction | IDeleteTimeEntrySuccessAction | IUpdateStatusTimeEntrySuccess | 
    ICopyTimeEntrySuccessAction | TApiCallAction;

export const actionCreators = {
    loadByAccount: (filter?:string, orderby?:string, filterValues?:any, daterange?:any): ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(0, [], filterValues,daterange));
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;

        return await timeEntriesService.getAll(accountId,filter,orderby)
        .then(data => {
            dispatch(loadSuccess(data.total, data.items,filterValues,daterange));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },
    loadForApproval: (filter?:string, orderby?:string, filterValues?:any, daterange?:any): ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(0, [], filterValues,daterange));
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;

        return await timeEntriesService.getForApproval(accountId,filter,orderby)
        .then(data => {
            dispatch(loadSuccess(data.total, data.items, filterValues, daterange));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },    
    loadExcelByAccount: (filter?:string, orderby?:string): ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await timeEntriesService.getExcelByAccount(accountId,filter,orderby)
        .then(data => {
            dispatch(downloadSuccess());
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },    
    loadByCurrentUser: (filter?:string, orderby?:string, filterValues?:any, daterange?:any): ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(0, [], filterValues,daterange));

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;

        return await timeEntriesService.getByCurrentUser(accountId,filter,orderby)
        .then(data => {
            dispatch(loadSuccess(data.total, data.items,filterValues,daterange));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },    
    loadExcelByCurrentUser: (filter?:string, orderby?:string): ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await timeEntriesService.getExcelCurrentUser(accountId,filter,orderby)
        .then(data => {
            dispatch(downloadSuccess());
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },   
    loadByProject: (projectid:string,filter?:string, orderby?:string, filterValues?:any, daterange?:any): ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(0, [],filterValues,daterange));

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;

        return await timeEntriesService.getByProject(accountId,projectid,filter,orderby)
        .then(data => {
            dispatch(loadSuccess(data.total, data.items,filterValues,daterange));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },
    loadExcelByProject: (projectid:string,filter?:string, orderby?:string): ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await timeEntriesService.getExcelByProject(accountId,projectid,filter,orderby)
        .then(data => {
            dispatch(downloadSuccess());
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },   
    loadByEmployee: (employeeid:string,filter?:string, orderby?:string, filterValues?:any, daterange?:any): ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(0,[],filterValues,daterange));

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;

        return await timeEntriesService.getByEmployee(accountId,employeeid,filter,orderby)
        .then(data => {
            dispatch(loadSuccess(data.total, data.items,filterValues,daterange));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },
    loadExcelByEmployee: (employeeid:string,filter?:string, orderby?:string): ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await timeEntriesService.getExcelByEmployee(accountId,employeeid,filter,orderby)
        .then(data => {
            dispatch(downloadSuccess());
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },     
    loadByCompany: (companyid:string,filter?:string, orderby?:string, filterValues?:any, daterange?:any): ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(0,[],filterValues,daterange));

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;

        return await timeEntriesService.getByCompany(accountId,companyid,filter,orderby)
        .then(data => {
            dispatch(loadSuccess(data.total, data.items,filterValues,daterange));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },
    loadExcelByCompany: (companyid:string,filter?:string, orderby?:string): ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await timeEntriesService.getExcelByCompany(accountId,companyid,filter,orderby)
        .then(data => {
            dispatch(downloadSuccess());
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },     
    loadByCustomer: (customerid:string,filter?:string, orderby?:string, filterValues?:any, daterange?:any): ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(0,[],filterValues,daterange));

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;

        return await timeEntriesService.getByCustomer(accountId,customerid,filter,orderby)
        .then(data => {
            dispatch(loadSuccess(data.total, data.items,filterValues,daterange));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },
    loadExcelByCustomer: (customerid:string,filter?:string, orderby?:string): ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await timeEntriesService.getExcelByCustomer(accountId,customerid,filter,orderby)
        .then(data => {
            dispatch(downloadSuccess());
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },       
    loadSummary: (): ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSummarySuccess([]));

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;

        return await timeEntriesService.getSummary(accountId)
        .then(data => {
            dispatch(loadSummarySuccess(data));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },
    save: (timeentry:TimeEntry) : ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        if(timeentry.id){
            return await timeEntriesService.update(accountId, timeentry.projectId, timeentry)
            .then(data => {
                dispatch(sharedActions.enqueueSnackbar({message: i18n.t("timeentries:msg.save.success")}));
                dispatch(updateSuccess(data));
            }).catch(error => {
                dispatch(sharedActions.enqueueSnackbar({message: i18n.t("timeentries:msg.save.error"), options: {variant: 'error'}}));
                dispatch(sharedActions.apiCallError(error));
                throw error;
                });
        }else{
            return await timeEntriesService.create(accountId, timeentry.projectId, timeentry)
            .then(data => {
                dispatch(sharedActions.enqueueSnackbar({message: i18n.t("timeentries:msg.save.success")}));
                dispatch(createSuccess(data));
            }).catch(error => {
                dispatch(sharedActions.enqueueSnackbar({message:  i18n.t("timeentries:msg.save.error"), options: {variant: 'error'}}));
                dispatch(sharedActions.apiCallError(error));
                throw error;
                });
        }
    },
    delete: (timeentryids:string[]) : ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        
        return await timeEntriesService.deleteMultiple(accountId, timeentryids)
        .then(data => {
            dispatch(sharedActions.enqueueSnackbar({message: i18n.t("timeentries:msg.delete.multiple.success",{count:data.length})}));
            dispatch(deleteSuccess(data));
        }).catch(error => {
            dispatch(sharedActions.enqueueSnackbar({message: i18n.t("timeentries:msg.delete.error"), options: {variant: 'error'}}));
            dispatch(sharedActions.apiCallError(error));
            throw error;
        });

    },
    copy: (timeentryids:string[]) : ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        
        return await timeEntriesService.copyMultiple(accountId, timeentryids)
        .then(data => {
            dispatch(sharedActions.enqueueSnackbar({message: i18n.t("timeentries:msg.copy.multiple.success",{count:data.length}),options: {variant: 'success'}}));
            dispatch(copySuccess(data));
        }).catch(error => {
            dispatch(sharedActions.enqueueSnackbar({message: i18n.t("timeentries:msg.copy.error"), options: {variant: 'error'}}));
            dispatch(sharedActions.apiCallError(error));
            throw error;
        });
    },    
    updateStatus: (timeentryids:string[], newstatus:TimeEntryStatus, invoiceRef?:string) : ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        
        return await timeEntriesService.updateStatus(accountId, timeentryids, newstatus, invoiceRef)
        .then(data => {
            dispatch(sharedActions.enqueueSnackbar({message: i18n.t("timeentries:msg.status.success"), options: {variant: 'success'}}));
            dispatch(updateStatusSuccess(data));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            dispatch(sharedActions.enqueueSnackbar({message: i18n.t("timeentries:msg.status.error"), options: {variant: 'error'}}));
            throw error;
        });
    },
    requestApproval: (filter?:string): ThunkAction<Promise<void>, ApplicationState, {}, TTimeEntryAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await timeEntriesService.requestApproval(accountId, filter)
        .then(data => {
            dispatch(sharedActions.enqueueSnackbar({message: i18n.t("timeentries:msg.status.success"), options: {variant: 'success'}}));
            dispatch(updateStatusSuccess(data));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            dispatch(sharedActions.enqueueSnackbar({message: i18n.t("timeentries:msg.status.error"), options: {variant: 'error'}}));
            throw error;
            });
    },       
}