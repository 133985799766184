import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import i18n from "i18next";
import telemetryService from "../services/telemetryService";
import { useLocation } from 'react-router-dom';
import { matchRoutes, RouteConfig } from "react-router-config";
import rootroutes from "../routes";

interface PageProps {
  className?:string,
  title:string,
  children?:React.ReactNode;
  routes?:RouteConfig[];
}

const Page: React.FC<PageProps> = ({ title, routes, children, ...rest }) => {
  const location = useLocation();
  
  useEffect(() => {
    const matched = matchRoutes(routes || rootroutes, location.pathname);
    let name = title;
    if(matched && matched.length > 0){
      name = matched[matched.length - 1]?.route.name || name;
    }
    telemetryService.sendPageview(location.pathname, name, i18n.language);

  }, [location.pathname,routes, title, i18n.language]);

  return (
    <div {...rest}>
      <Helmet htmlAttributes={{"lang": i18n.language}}>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
}

export default Page;
