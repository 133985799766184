import { Company } from "../../models/Company";
import { ICompaniesState } from "../state";
import { CompanyEmployeeActionTypes, TCompanyEmployeeAction } from "./actions";

export const companyEmployeeReducer = (prevstate:ICompaniesState, action:TCompanyEmployeeAction) : ICompaniesState => {
    if(Object.values(CompanyEmployeeActionTypes).includes(action.type)){
        return {...prevstate, companies: prevstate.companies.map(x => {
            if(x.id === action.companyId)
            {
                return updateCompanyEmployees(x,action);
            }
            return x;
        })};
    }
    return prevstate;
};

const updateCompanyEmployees = (company:Company,action:TCompanyEmployeeAction):Company => {
    
    switch(action.type){
        case CompanyEmployeeActionTypes.COMPANYEMPLOYEES_LOAD_SUCCESS:
            return {...company, employees: action.companyEmployees};
        case CompanyEmployeeActionTypes.COMPANYEMPLOYEES_CREATE_SUCCESS:
            return {...company, employees: [...action.companyEmployees, ...company.employees  || []]};
        case CompanyEmployeeActionTypes.COMPANYEMPLOYEES_DELETE_SUCCESS:
            return {...company, employees: company.employees?.filter(e =>
                action.companyEmployeesIds.indexOf(e.employeeId) === -1
            )};                                        
        default:
            return company;
    }
};