import React, {  useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment, { Moment } from 'moment';
import MomentUtils from "@date-io/moment";
import clsx from 'clsx';
import { DatePicker, DatePickerView, MuiPickersUtilsProvider } from "@material-ui/pickers";
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import { Button, IconButton, makeStyles, TextFieldProps } from "@material-ui/core";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import DateRangeIcon from '@material-ui/icons/DateRange';

import { ToolbarComponentProps } from "@material-ui/pickers/Picker/Picker";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { TFunction } from "i18next";


const useStyles = makeStyles((theme) => ({

    toolbar: {
        height: 120,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",

    },
    toolbarButtons: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        margin: "20px 0"
    },
    toggleButton: {
        color: theme.palette.common.white,
        '&$toggleButtonSelected': {
            color: theme.palette.common.black,
            backgroundColor: theme.palette.primary.contrastText,
            '&:hover': {
                color: theme.palette.common.black,
                backgroundColor: theme.palette.primary.contrastText
            }
        }

    },
    toggleButtonSelected: {},
    dayWrapper: {
        position: "relative",
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: "2px",
        color: "inherit",
    },
    customDayHighlight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "2px",
        right: "2px",
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: "50%",
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
        color: theme.palette.text.disabled,
    },
    highlight: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    firstHighlight: {
        extend: "highlight",
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
    },
    endHighlight: {
        extend: "highlight",
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
    }

}));


export enum DateRangePickerViews {
    DATE =  "date" ,
    WEEK = "week",
    MONTH = "month",
    YEAR = "year"
}

export const getDisplayFormatted = (date: Date | undefined, view:DateRangePickerViews, t: TFunction, lang:string) =>{
    const mdate = moment(date);
    mdate.locale(lang);
    if (mdate && mdate.isValid()) {
        switch (view) {
            case DateRangePickerViews.DATE:
                return mdate.format("LL");
            case DateRangePickerViews.WEEK:
                return t("common:date.week") + " " + mdate.isoWeek() + mdate.format(", YYYY");
            case DateRangePickerViews.MONTH:
                return mdate.format("MMMM, YYYY");
            case DateRangePickerViews.YEAR:
                return mdate.format("YYYY");
        }
    }
    return "";
}

declare type CustomToolbarComponentProps = ToolbarComponentProps & {
    setViews: (view: DateRangePickerViews) => void;
    currentView: DateRangePickerViews;
}

const CustomToolbar: React.FC<CustomToolbarComponentProps> = ({
    date,
    currentView,
    views,
    setOpenView,
    isLandscape,
    openView,
    onChange,
    setViews
}) => {

    const [display, setDisplay] = useState({ part1: "", part2: ""});

    const handleChangeView = (e: any, view: DateRangePickerViews) => {
        if (view) {
            setViews(view);
            if (view === DateRangePickerViews.WEEK) {
                setOpenView("date");
            } else {
                setOpenView(view as DatePickerView);
            }
        }
    }

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const isYear = currentView === DateRangePickerViews.YEAR;
    const isMonth = currentView === DateRangePickerViews.MONTH;

    useEffect(() =>{
        setDisplayFormat();
    },[date,currentView,i18n.language]);

    const setDisplayFormat = () =>{
        const ddate = moment(date);
        ddate.locale(i18n.language);
        if (ddate) {
            switch (currentView) {
                case DateRangePickerViews.DATE:
                    setDisplay({part1: ddate.format("LL").split(",")[0], part2: ddate.format("YYYY")});
                    break;
                case DateRangePickerViews.WEEK:
                    setDisplay({part1: "Week " + ddate.isoWeek(), part2: ddate.format("YYYY")})
                    break;
                case DateRangePickerViews.MONTH:
                    setDisplay({part1: ddate.format("MMMM"), part2: ddate.format("YYYY")})
                    break;
            }
        }
    };

    return (
        <PickerToolbar className={classes.toolbar} isLandscape={isLandscape}>
            <ToggleButtonGroup className={classes.toolbarButtons} size="small" color="white" aria-label="contained primary button group" exclusive
                value={currentView} onChange={handleChangeView} >
                <ToggleButton value={DateRangePickerViews.DATE} classes={{ root: classes.toggleButton, selected: classes.toggleButtonSelected }}>{t("date.day")}</ToggleButton>
                <ToggleButton value={DateRangePickerViews.WEEK} classes={{ root: classes.toggleButton, selected: classes.toggleButtonSelected }}>{t("date.week")}</ToggleButton>
                <ToggleButton value={DateRangePickerViews.MONTH} classes={{ root: classes.toggleButton, selected: classes.toggleButtonSelected }}>{t("date.month")}</ToggleButton>
                <ToggleButton value={DateRangePickerViews.YEAR} classes={{ root: classes.toggleButton, selected: classes.toggleButtonSelected }}>{t("date.year")}</ToggleButton>
                {/* <ToggleButton value="custom" classes={{ root: classes.toggleButton, selected: classes.toggleButtonSelected }}>Custom</ToggleButton> */}
            </ToggleButtonGroup>
            <span>
                {!isYear &&
                    <ToolbarButton
                        onClick={() => setOpenView("month")}
                        variant="h4"
                        selected={!isMonth && openView === "month"}
                        label={display.part1 + ","}
                        style={{ marginRight: 5 }}
                    />}
                <ToolbarButton
                    onClick={() => setOpenView('year')}
                    variant={'h4'}
                    label={display.part2}
                    selected={!isYear && openView === "year"}
                />
            </span>
        </PickerToolbar>
    );
}

const getViews = (view?: DateRangePickerViews):DatePickerView[] => {
    switch (view) {
        case DateRangePickerViews.DATE:
            return ["date"];
        case DateRangePickerViews.WEEK:
            return ["date"];
        case DateRangePickerViews.MONTH:
            return ["month"];
        case DateRangePickerViews.YEAR:
            return ["year"];
    }
    return [];
}

declare interface AppRangePickerProps  {
    view?: DateRangePickerViews;
    start?:Date;
    end?:Date;
    onChange?:(view:DateRangePickerViews, start:Date, end:Date) => void;
}

const AppRangePicker: React.FC<AppRangePickerProps> = ({view, start, end, onChange}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [internalView, setInternalView] = useState<DateRangePickerViews>(view || DateRangePickerViews.WEEK);
    const [startDate, setStartDate] = useState(start);
    const [endDate, setEndDate] = useState(end);
    const [currentView, setCurrentView] = useState<DateRangePickerViews>(view || DateRangePickerViews.WEEK);
    const [views, setViews] = useState<DatePickerView[]>(getViews(view));
    const [display, setDisplay] = useState("");

    const handleSetView = (view: DateRangePickerViews) => {
        setCurrentView(view);
        setViews(getViews(view));
    }

    useEffect(() => {
        setDisplayFormat();
    },[startDate, internalView,i18n.language]);

    const setDisplayFormat = () =>{
        setDisplay(getDisplayFormatted(startDate,internalView,t,i18n.language));
    }

    const getCustomToolbar = (props: ToolbarComponentProps) => {
        return <CustomToolbar {...props} currentView={currentView} setViews={handleSetView} />
    };

    const getCustomTextfield = (props: TextFieldProps) => {
        return <Button onClick={() => setIsOpen(true)} startIcon={<DateRangeIcon />}>{display}</Button>
    }

    const handleDateChange = (value: Moment | null) => {
        if (value && value.isValid()) {
            let start = value.clone().utc(true);
            let end = value.clone().utc(true);

            switch (currentView) {
                case DateRangePickerViews.DATE:
                    start = start.startOf('day');
                    end = end.endOf('day');
                    break;
                case DateRangePickerViews.WEEK:
                    start = start.startOf('week');
                    end = end.endOf('week');
                    break;
                case DateRangePickerViews.MONTH:
                    start = start.startOf('month');
                    end = end.endOf('month');
                    break;
                case DateRangePickerViews.YEAR:
                    start = start.startOf('year');
                    end = end.endOf('year');
                    break;
            }

            setInternalView(currentView);
            setStartDate(start.toDate());
            setEndDate(end.toDate());
            if(onChange){
                onChange(currentView, start.toDate(), end.toDate());
            }
        }
    };

    const renderWrappedDay = (day: MaterialUiPickersDate, selectedDate: MaterialUiPickersDate, dayInCurrentMonth: boolean, dayComponent: JSX.Element) => {

        let dateClone = day?.clone();
        let selectedDateClone = selectedDate?.clone();

        let start = selectedDateClone?.clone();
        let end = selectedDateClone?.clone();

        switch (currentView) {
            case DateRangePickerViews.WEEK:
                start = start?.startOf('week');
                end = end?.endOf('week');
                break;
            case DateRangePickerViews.MONTH:
                start = start?.startOf('month');
                end = end?.endOf('month');
                break;
            case DateRangePickerViews.YEAR:
                start = start?.startOf('year');
                end = end?.endOf('year');
                break;
        }

        const dayIsBetween = dateClone?.isBetween(start, end, "d", "[]");
        const isFirstDay = dateClone?.isSame(start, "d");
        const isLastDay = dateClone?.isSame(end, "d");

        const wrapperClassName = clsx({
            [classes.highlight]: dayIsBetween,
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay,
        });

        const dayClassName = clsx(classes.day, {
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
            [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
        });


        return (
            <div className={wrapperClassName}>
                <IconButton className={dayClassName}>
                    <span> {dateClone?.format('D')} </span>
                </IconButton>
            </div>
        );
    };

    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={i18n.language}>
            <DatePicker
                showTodayButton={true}
                views={views}
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                label=""
                value={startDate}
                onChange={handleDateChange}
                ToolbarComponent={getCustomToolbar}
                renderDay={renderWrappedDay}
                TextFieldComponent={getCustomTextfield}
                cancelLabel={t("cancel")}
                clearLabel={t("clear")}
                okLabel={t("ok")}
                todayLabel={t("date.today")}
            />
        </MuiPickersUtilsProvider>
    )
};

export default AppRangePicker;