import { Reducer } from "redux";
import { initialReportState, IReportsState } from "./state";
import { ReportActionTypes, TReportAction } from "./actions";

export const reducer: Reducer<IReportsState, TReportAction> = (prevstate = initialReportState, action): IReportsState => {
    switch (action.type) {
        case ReportActionTypes.PROJECTTOTALS_LOAD_SUCCESS:
            return { ...prevstate, projectTotals: { 
                total: action.total, 
                projects: action.projects, 
                filter: action.filter || prevstate.projectTotals.filter}, 
                daterange: action.daterange || prevstate.daterange };

        case ReportActionTypes.EMPLOYEETOTALS_LOAD_SUCCESS:
            return { ...prevstate, employeeTotals: { 
                total: action.total, 
                employees: action.employees, 
                filter: action.filter || prevstate.employeeTotals.filter},
                daterange: action.daterange || prevstate.daterange  };     
        default:
            return prevstate;
    }
}
