import { Employee } from "../models/Employee";

export interface IEmployeesState {
    total:number;
    employees: Array<Employee>;
}

export const initialEmployeesState: IEmployeesState = {
    total:0,
    employees:[]
}