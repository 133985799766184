import * as userService from "../../services/userService";
import i18n from "../../i18n";
import { actionCreators as sharedActions, TApiCallAction } from "../shared/actions";
import { loadSuccess as loadCountriesSuccess } from "../country/actions";
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from "redux";
import { NormalizedRole } from "../models/Role";
import { login as authLogin, renewToken as authRenew, logout as authLogout, getUser as authGetUser } from "../../services/authService";
import { User } from "oidc-client";
import { store } from "../../App";
import { UserSetting, UserSettingKey } from "./state";

// action types
export enum UserActionTypes {
  USER_LOAD_SUCCESS = "USER_LOAD_SUCCESS",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  RENEW_SUCCESS = "RENEW_SUCCESS",
  LOGOUT = "LOGOUT",
  LOAD_SETTINGS_SUCCESS = "LOAD_SETTINGS_SUCCESS"
}

export interface IUserAction {
  type: UserActionTypes;
}

export interface IUserLoginSuccessAction extends IUserAction {
    type: UserActionTypes.LOGIN_SUCCESS;
    authUser: User | null;
}

export interface IUserRenewSuccessAction extends IUserAction {
    type: UserActionTypes.RENEW_SUCCESS;
    authUser: User | null;
}

export interface IUserLogoutAction extends IUserAction {
    type: UserActionTypes.LOGOUT;
}

export interface IUserLoadSuccessAction extends IUserAction {
    type: UserActionTypes.USER_LOAD_SUCCESS;
    username: string;
    name: string;
    role: NormalizedRole;
    accountId: string;
    accountName: string;
    userSettings: UserSetting[];
}

export interface IUserLoadSettingsSuccessAction extends IUserAction {
    type: UserActionTypes.LOAD_SETTINGS_SUCCESS;
    userSettings: UserSetting[];
}

function loginSuccess(authUser: User | null): IUserLoginSuccessAction{
    return {
        type: UserActionTypes.LOGIN_SUCCESS,
        authUser
    }
}

function renewSuccess(authUser: User | null): IUserRenewSuccessAction{
    return {
        type: UserActionTypes.RENEW_SUCCESS,
        authUser
    }
}

async function loadSettingsSuccess(userSettings: UserSetting[]): Promise<IUserLoadSettingsSuccessAction> {

    // check settings
    const langSetting = userSettings.find((x:UserSetting) => x.key === UserSettingKey.LANGUAGE);
    if(langSetting && langSetting.value !== i18n.language){
        await i18n.changeLanguage(langSetting.value);
    }
        
    return {
        type: UserActionTypes.LOAD_SETTINGS_SUCCESS,
        userSettings
    }
}

function userLoadSuccess(username:string, name:string, role:NormalizedRole, accountId:string, accountName:string, userSettings: UserSetting[]): IUserLoadSuccessAction{
    return {
        type: UserActionTypes.USER_LOAD_SUCCESS,
        username,
        name,
        role,
        accountId,
        accountName,
        userSettings
    }
}

export type TUserAction = IUserLoginSuccessAction 
 | IUserRenewSuccessAction 
 | IUserLogoutAction 
 | IUserLoadSuccessAction 
 | TApiCallAction 
 | IUserLoadSettingsSuccessAction;

export const actionCreators = {
    loadUser: (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch, getState):Promise<void> => {
        let user = await authGetUser();
        if(user !== null && user !== undefined)
        {
            dispatch(loginSuccess(user));
        }else{
            return authLogin();  
        }
    },
    renewUser: async ():Promise<User> => {
        return await authRenew().then(user =>{
            store.dispatch(renewSuccess(user))
            return user;
        }).catch(async (error) => {
            await authLogin();
            store.dispatch(sharedActions.apiCallError(error));
            throw error;
        })
    },    
    loadUserData: (): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch, getState:any):Promise<void> => {
        try{
            dispatch(sharedActions.beginApiCall());
            await userService.getinfo()
            .then(async (data:any) => {
                if(data.status === 401){
                    await authLogout();
                }else{
                    dispatch(loadCountriesSuccess(data.countries));
                    dispatch(await loadSettingsSuccess(data.userSettings));

                    const account = data.accounts.filter((x:any) => x.isCurrent===true)[0];
                    dispatch(userLoadSuccess(data.userName, data.name, account.role, account.accountId, account.Name, data.userSettings));
                }
            }).catch(async (error) => {
                await authLogout();
                //await authLogin();
                store.dispatch(sharedActions.apiCallError(error));
                throw error;
            })
        }catch(error){
            dispatch(sharedActions.apiCallError(error));
            throw error;
        } 
    },

    login: (username:string, password:string): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch, getState):Promise<void> => {
        await authLogin();
    },
    logout: (): ThunkAction<void,{},{},IUserLogoutAction> => async (dispatch, getState):Promise<void>  => {
        await authLogout();
    },
    updateUserSettings: (userSettings:UserSetting[]): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch, getState):Promise<void> => {

        dispatch(sharedActions.beginApiCall());
        
        return await userService.updateSettings(userSettings)
            .then(async data => {
                dispatch(await loadSettingsSuccess(data));
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
            });
            
    }
}
