import React, { useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import { ApplicationState } from '../store';
import routes from '../routes';
import { connect } from 'react-redux';
import { actionCreators as appActions} from  "../store/app/actions";
import ErrorHandler from "./ErrorHandler";
import telemetryService from "../services/telemetryService";

export interface AppLoaderProps {
    isLoading: boolean;
    userId: string | null;
    loadApp: () => void;
}

const AppLoader: React.FC<AppLoaderProps> = ({isLoading,userId, loadApp}) => {
    // load google tag manager
    useEffect(() => {
      telemetryService.init(userId);
    }, [userId]);

    useEffect(() => {
        if(isLoading){
            loadApp();
        }
    }, [isLoading]);

    return (isLoading ? <></> : 
        <ErrorHandler>
          {renderRoutes(routes)}
        </ErrorHandler>
      )
  }
  
export default connect((state: ApplicationState) => {
      return {
        isLoading: state.appSettings.loading,
        userId: state.user.authUser !== null ? state.user.authUser?.profile.sub : null
      };
    },
    {
        loadApp: appActions.loadApp
    }
)(AppLoader);
  