import { handleResponse, handleError, defaultSecureHeaders,getFilterAndSort } from "./apiUtils";
import { Employee } from "../store/models/Employee";
import { store } from "../App";
import { PagedResults } from "../store/models/PagedResults";

export async function getAll(accountid:string, filter?:string, orderby?:string):Promise<PagedResults<Employee>> {
  return getByUrl("/employees/", accountid, filter, orderby);
}

export async function getByCompany(accountid:string, companyid:string, filter?:string, orderby?:string):Promise<PagedResults<Employee>> {
  return getByUrl("/companies/" + companyid + "/employees/", accountid, filter, orderby);
}

export async function getById(accountid:string, id:string):Promise<Employee> {
  return getByUrl("/employees/" + id, accountid);
}

async function getByUrl(url:string, accountid:string, filter?:string, orderby?:string):Promise<any> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + url + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function create(accountid:string, employee:Employee):Promise<Employee> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/employees", {
    method: "POST", 
    headers: await defaultSecureHeaders(),
    body: JSON.stringify(employee)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function update(accountid:string, employee:Employee):Promise<Employee> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/employees/" + employee.id, {
    method: "POST", 
    headers: await defaultSecureHeaders(),
    body: JSON.stringify(employee)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function setfav(accountid:string, employeeids:string[], setfav:boolean):Promise<string[]> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/employees/setfav", {
    method: "POST", 
    headers: await defaultSecureHeaders(),
    body: JSON.stringify({
      employeeids,
      setfav
    })
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteMultiple(accountid:string, employeeids:string[]):Promise<string[]> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/employees", { 
    method: "DELETE",
    headers: await defaultSecureHeaders(),
    body: JSON.stringify({
      employeeids
    })
  })
  .then(handleResponse)
  .catch(handleError);
}