import { Expense } from "../models/Expense";

export interface IExpensesState {
    total:number;
    expenses: Array<Expense>;
}

export const initialExpensesState  = {
    total:0,
    expenses:[]
}