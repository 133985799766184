import { handleResponse, handleError, defaultSecureHeaders,getFilterAndSort } from "./apiUtils";
import { Customer } from "../store/models/Customer";
import { store } from "../App";
import { PagedResults } from "../store/models/PagedResults";

export async function getAll(accountid:string, filter?:string, orderby?:string):Promise<PagedResults<Customer>> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + "/customers" + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getById(accountid:string, id:string):Promise<Customer> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/customers/" + id, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getByCurrentUser(accountid:string, filter?:string, orderby?:string):Promise<PagedResults<Customer>> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + "/your/customers" + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function create(accountid:string, customer:Customer):Promise<Customer> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/customers", {
    method: "POST", 
    headers: await defaultSecureHeaders(),
    body: JSON.stringify(customer)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function update(accountid:string, customer:Customer):Promise<Customer> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/customers/" + customer.id, {
    method: "POST", 
    headers: await defaultSecureHeaders(),
    body: JSON.stringify(customer)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteMultiple(accountid:string, customerids:string[]):Promise<string[]> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/customers", { 
    method: "DELETE",
    headers: await defaultSecureHeaders(),
    body: JSON.stringify({
      customerids
    })
  })
  .then(handleResponse)
  .catch(handleError);
}