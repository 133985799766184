import { Reducer } from "redux";
import { IExpensesState, initialExpensesState } from "./state";
import { ExpenseActionTypes, TExpenseAction } from "./actions";

export const reducer:Reducer<IExpensesState> = (prevstate:IExpensesState = initialExpensesState, action:TExpenseAction) : IExpensesState => {
    switch(action.type){
        case ExpenseActionTypes.EXPENSES_LOAD_SUCCESS:
            return {...prevstate,  total: action.total, expenses: action.expenses};

        case ExpenseActionTypes.EXPENSES_DELETE_SUCCESS:
            return {...prevstate, expenses: prevstate.expenses.filter(expense =>
                action.expenseids.indexOf(expense.id) === -1
            )};        
                                                    
        default:
            return prevstate;
    }
}
