import { Customer } from "../models/Customer";

export interface ICustomersState {
    total:number;
    customers: Array<Customer>;
}

export const initialCustomersState  = {
    total:0,
    customers:[]
}