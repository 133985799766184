import { Reducer } from "redux";
import { IProjectsState, initialProjectsState } from "./state";
import { ProjectActionTypes, TProjectAction } from "./actions";

export const reducer:Reducer<IProjectsState> = (prevstate:IProjectsState = initialProjectsState, action:TProjectAction) : IProjectsState => {
    switch(action.type){
        case ProjectActionTypes.PROJECTS_LOAD_SUCCESS:
            return {...prevstate, total:action.total, projects: action.projects, 
                filter: prevstate.filter.map(f => f.key === action.filter?.key ? action.filter : f)};
        case ProjectActionTypes.PROJECTTYPES_LOAD_SUCCESS:
            return {...prevstate, projectTypes: action.projectTypes};
        case ProjectActionTypes.PROJECT_CREATE_SUCCESS:
            return {...prevstate,projects: [...prevstate.projects,{ ...action.project }]};
        case ProjectActionTypes.PROJECT_UPDATE_SUCCESS:
            return {...prevstate, projects: prevstate.projects.map(project =>
                project.id === action.project.id ? action.project : project
            )};  
        case ProjectActionTypes.PROJECT_DELETE_SUCCESS:
            return {...prevstate, projects: prevstate.projects.filter(project =>
                action.projectids.indexOf(project.id) === -1
            )};                                        
        default:
            return prevstate;
    }
}
