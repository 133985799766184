import { Company } from "../models/Company";

export interface ICompaniesState {
    total:number;
    companies: Array<Company>;
}

export const initialCompaniesState  = {
    total:0,
    companies:[]
}