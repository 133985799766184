import React from "react";
import { useLocation, Redirect } from 'react-router-dom';
import AppRoutes from '../routeConstants';

interface ErrorHandlerProps {
  children?:React.ReactNode;
}

const ErrorHandler = (props:any) => {
  const location = useLocation();

  switch (location.state?.errorStatusCode) {
    case 401:
      return <Redirect to={AppRoutes.ERRORS_401}  />
    case 403:
      return <Redirect to={AppRoutes.ERRORS_403}  />
    case 404:
      return <Redirect to={AppRoutes.ERRORS_404}  />      
    default:
      return props.children
  };
};

export default ErrorHandler;