import { ThunkAction } from 'redux-thunk';
import { Image } from "../models/Image";
import * as imagesService from "../../services/imagesService";
import { actionCreators as sharedActions,TApiCallAction } from "../shared/actions";
import { ApplicationState } from "../"

export enum ImageActionTypes {
    IMAGES_LOAD_SUCCESS = "IMAGES_LOAD_SUCCESS",
}

export interface IImageAction {
    type: ImageActionTypes;
  }

export interface IImageLoadSuccess extends IImageAction {
    type: ImageActionTypes.IMAGES_LOAD_SUCCESS;
    total: number;
    images:Array<Image>;
}

function loadSuccess(total: number, images:Array<Image>, filter:any): IImageLoadSuccess {
    return {
        type: ImageActionTypes.IMAGES_LOAD_SUCCESS,
        total,
        images
    }
}

export type TImageAction = IImageLoadSuccess | TApiCallAction;

export const actionCreators = {
    loadByProject: (projectid:string,filter?:string, orderby?:string, filterValues?:any): ThunkAction<Promise<void>, ApplicationState, {}, TImageAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(0, [],filterValues));

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;

        return await imagesService.getByProject(accountId,projectid,filter,orderby)
        .then(data => {
            dispatch(loadSuccess(data.total, data.items,filterValues));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    }
}