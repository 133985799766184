import { store } from "../App";
import { handleResponse, handleError, defaultSecureHeaders, getFilterAndSort, defaultAccessToken } from "./apiUtils";
import { PagedResults } from "../store/models/PagedResults";
import { Expense } from "../store/models/Expense";


export async function getByProject(accountid:string, projectid:string, filter?:string, orderby?:string):Promise<PagedResults<Expense>> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + "/projects/" + projectid +"/expenses" + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getExcelByProject(accountid:string, projectid:string):Promise<void> {
   const state = store.getState();
   var token = await defaultAccessToken(); 
   window.location.href = state.appSettings.apiBaseUrl + accountid + "/projects/" + projectid + "/expenses/excel?" + token;
}

 export async function deleteMultiple(accountid:string, projectid:string, expenseids:string[]):Promise<string[]> {
    const state = store.getState();
    return fetch(state.appSettings.apiBaseUrl + accountid + "/projects/" + projectid +  "/expenses", { 
      method: "DELETE",
      headers: await defaultSecureHeaders(),
      body: JSON.stringify({
        expenseids
      })
    })
    .then(handleResponse)
    .catch(handleError);
  }
    
