import React from 'react';
import { useDispatch,  connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { actionCreators as sharedActions } from '../store/shared/actions'
import { ApplicationState } from "../store";

let displayed = new Array<any>();

interface AppNotifierProps {
    notifications: Array<any>;
}

const AppNotifier: React.FC<AppNotifierProps> = ({notifications}) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id:any) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id:any) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    React.useEffect(() => {
        let defaultOptions = {
            anchorOrigin:{ vertical: 'bottom', horizontal: 'right' },
            autoHideDuration: 3000
        }
        
        notifications.forEach(({ key, message, options = defaultOptions, dismissed = false }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...defaultOptions, ...options,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // removen this snackbar from redux store
                    dispatch(sharedActions.removeSnackbar(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};


export default connect(
    (state: ApplicationState) => {
      return { 
          notifications: state.apiState.notifications
      };
    },
    {
    }
  )(AppNotifier);