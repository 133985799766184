import { IAppSettings, initialAppSettings } from "./state";
import { TAppAction, AppActionTypes } from "./actions";
import { Reducer } from "redux";

export const appSettingsReducer: Reducer<IAppSettings> = (prevstate = initialAppSettings, action:TAppAction): IAppSettings => {
    switch (action.type){
      case  AppActionTypes.APPSETTINGS_LOAD_SUCCESS:
        return {
          ...action.appSettings, loading: false
        }
      default:
          return prevstate; 
    }
  };
  