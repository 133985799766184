import { handleResponse, handleError, defaultSecureHeaders,getFilterAndSort } from "./apiUtils";
import { Contact } from "../store/models/Contact";
import { store } from "../App";
import { PagedResults } from "../store/models/PagedResults";

export async function getAll(accountid:string, customerid:string, filter?:string, orderby?:string):Promise<PagedResults<Contact>> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + "/customers/" + customerid + "/contacts" + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function create(accountid:string, customerid:string, contact:Contact):Promise<Contact> {
    const state = store.getState();
    return fetch(state.appSettings.apiBaseUrl + accountid + "/customers/" + customerid + "/contacts", {
      method: "POST", 
      headers: await defaultSecureHeaders(),
      body: JSON.stringify(contact)
    })
      .then(handleResponse)
      .catch(handleError);
  }
  
  export async function update(accountid:string, customerid:string, contact:Contact):Promise<Contact> {
    const state = store.getState();
    return fetch(state.appSettings.apiBaseUrl + accountid + "/customers/" + customerid + "/contacts/" + contact.id, {
      method: "POST", 
      headers: await defaultSecureHeaders(),
      body: JSON.stringify(contact)
    })
      .then(handleResponse)
      .catch(handleError);
  }
  
  export async function deleteMultiple(accountid:string, customerid:string, contactids:string[]):Promise<string[]> {
    const state = store.getState();
    return fetch(state.appSettings.apiBaseUrl + accountid + "/customers/" + customerid + "/contacts", { 
      method: "DELETE",
      headers: await defaultSecureHeaders(),
      body: JSON.stringify({
        contactids
      })
    })
    .then(handleResponse)
    .catch(handleError);
  }