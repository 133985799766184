import moment from "moment";
import { DateRangePickerViews } from "../../components/AppRangePicker";
import { ProjectTotals } from "../models/Reports/ProjectTotals";
import { EmployeeTotals } from "../models/Reports/EmployeeTotals";
import { IFilter, ProjectsFilterValues } from "../project/state";

export interface IReportsState {
    projectTotals: IProjectTotalsState;
    employeeTotals: IEmployeeTotalsState;
    daterange: {
        view:DateRangePickerViews;
        start?:Date;
        end?:Date;
    };
}

export interface IProjectTotalsState {
    total:number;
    projects: Array<ProjectTotals>;
    filter: IFilter;
}

export interface IEmployeeTotalsState {
    total:number;
    employees: Array<EmployeeTotals>;
    filter: any;
}

export const initialProjectTotalsState  = {
    total:0,
    projects:[],
    filter: ProjectsFilterValues
}

export const initialEmployeeTotalsState  = {
    total:0,
    employees:[],
    filter: {}
}

export const initialReportState  = {
    projectTotals: initialProjectTotalsState,
    employeeTotals: initialEmployeeTotalsState,
    daterange:{
        view:DateRangePickerViews.WEEK,
        start: moment().utc(true).startOf('week').toDate(),
        end: moment().utc(true).endOf('week').toDate()
    }
}
