import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import backend from "i18next-xhr-backend";
import moment, { WeekSpec } from "moment";
import "moment/locale/nl";

const defaultLng = "en";
var w: WeekSpec = {
  dow: 1, doy: 4
};

i18n
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    //debug: true,
    ns: ["common"],
    defaultNS: "common",
    lng: defaultLng,
    fallbackLng: defaultLng,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
        wait: true
    },
  },() => {

  });

  i18n.on("languageChanged", (lng:string) =>{
    moment.updateLocale(lng, {
      week: w
    });

  });

  export default i18n;