import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1
  }
});

const NavMenuAnonymous: React.FunctionComponent<{}> = props => {
  let classes = useStyles({});

  return (
    <AppBar position="fixed">
      <Toolbar> 
        <Typography variant="h4" className={classes.title}>
          Sharphours
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default NavMenuAnonymous;
