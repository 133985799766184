import * as H from 'history';

export const AppRoutes = {
    ROOT: "/",
    // AUTH: "/auth",
    // AUTH_LOGIN: "/auth/login",
    // AUTH_REGISTER: "/auth/register",
    OIDC_CALLBACK: "/signin-callback",
    ERRORS: "/errors",
    ERRORS_401: "/errors/errors-401",
    ERRORS_403: "/errors/errors-403",
    ERRORS_404: "/errors/errors-404",
    ERRORS_500: "/errors/errors-500",
    ACCOUNT: "/account",
    ACCOUNT_REGISTER: "/account/register",
    ACCOUNT_CONFIRM: "/account/confirm",
    DASHBOARD: "/dashboard",
    PROFILE: "/profile",
    PROFILE_TAB: "/profile/:tab",
    PROFILE_PREFERENCES: "/profile/preferences",
    PROJECTS: "/projects",
    PROJECTS_ADD: "/projects/add",
    PROJECTS_EDIT: "/projects/:id/edit",
    PROJECTS_DETAILS: "/projects/:id",  
    PROJECTS_DETAILS_TAB: "/projects/:id/:tab",
    PROJECTS_INFO: "/projects/:id/info", 
    PROJECTS_TEAM: "/projects/:id/team", 
    PROJECTS_TIMEENTRIES: "/projects/:id/timeentries", 
    PROJECTS_TIMEENTRIES_ADD: "/projects/:id/timeentries/add",
    PROJECTS_TIMEENTRIES_EDIT: "/projects/:id/timeentries/:timeentryid", 
    PROJECTS_EXPENSES: "/projects/:id/expenses", 
    PROJECTS_IMAGES: "/projects/:id/images", 
    CUSTOMERS: "/customers",
    CUSTOMERS_ADD: "/customers/add",
    CUSTOMERS_EDIT: "/customers/:id/edit",
    CUSTOMERS_DETAILS: "/customers/:id",  
    CUSTOMERS_DETAILS_TAB: "/customers/:id/:tab", 
    CUSTOMERS_INFO: "/customers/:id/info", 
    CUSTOMERS_CONTACTS: "/customers/:id/contacts", 
    CUSTOMERS_CONTACTS_ADD: "/customers/:id/contacts/add", 
    CUSTOMERS_CONTACTS_EDIT: "/customers/:id/contacts/:contactid", 
    CUSTOMERS_TIMEENTRIES: "/customers/:id/timeentries", 
    CUSTOMERS_TIMEENTRIES_ADD: "/customers/:id/timeentries/add",
    CUSTOMERS_TIMEENTRIES_EDIT: "/customers/:id/timeentries/:timeentryid",         
    CUSTOMERS_PROJECTS: "/customers/:id/projects", 
    COMPANIES: "/companies",
    COMPANIES_ADD: "/companies/add",
    COMPANIES_DETAILS: "/companies/:id",
    COMPANIES_DETAILS_TAB: "/companies/:id/:tab",
    COMPANIES_EDIT: "/companies/:id/edit",  
    COMPANIES_INFO: "/companies/:id/info", 
    COMPANIES_TIMEENTRIES: "/companies/:id/timeentries", 
    COMPANIES_TIMEENTRIES_ADD: "/companies/:id/timeentries/add",
    COMPANIES_TIMEENTRIES_EDIT: "/companies/:id/timeentries/:timeentryid",         
    COMPANIES_PROJECTS: "/companies/:id/projects", 
    COMPANIES_EMPLOYEES: "/companies/:id/employees", 
    EMPLOYEES: "/employees",
    EMPLOYEES_ADD: "/employees/add",
    EMPLOYEES_DETAILS: "/employees/:id",    
    EMPLOYEES_DETAILS_TAB: "/employees/:id/:tab",
    EMPLOYEES_EDIT: "/employees/:id/edit",
    EMPLOYEES_INFO: "/employees/:id/info", 
    EMPLOYEES_TIMEENTRIES: "/employees/:id/timeentries", 
    EMPLOYEES_TIMEENTRIES_ADD: "/employees/:id/timeentries/add",
    EMPLOYEES_TIMEENTRIES_EDIT: "/employees/:id/timeentries/:timeentryid",     
    EMPLOYEES_PROJECTS: "/employees/:id/projects", 
    REPORTS_PROJECTTOTALS: "/reports/projecttotals",
    REPORTS_EMPLOYEETOTALS: "/reports/employeetotals",
    TIMEENTRIES: "/timeentries",
    TIMEENTRIES_TAB: "/timeentries/:tab",
    TIMEENTRIES_APPROVE: "/timeentries/approve",
    TIMEENTRIES_APPROVE_EDIT: "/timeentries/approve/:timeentryid",
    TIMEENTRIES_ALL: "/timeentries/all",
    TIMEENTRIES_ALL_ADD: "/timeentries/all/add",
    TIMEENTRIES_ALL_EDIT: "/timeentries/all/:timeentryid",
    TIMEENTRIES_YOURS: "/timeentries/yours",
    TIMEENTRIES_YOURS_ADD: "/timeentries/yours/add/:projectid?",
    TIMEENTRIES_YOURS_EDIT: "/timeentries/yours/:timeentryid",
}

interface PlainObject {
    [key: string]: any
  }

export const urlRestoreAction = (path: H.Path, pathParams?: PlainObject) => {
  let pathToGo = path
  
  if (pathParams) {
    Object.keys(pathParams).forEach(param => {
      pathToGo = pathToGo.replace(`:${param}`, pathParams[param])
    })
  }
  return {pathname:pathToGo, state:{restore:true}};

}

 export const urlAction = (path: H.Path, pathParams?: PlainObject, restore:boolean = false) => {
  let pathToGo = path

  if (pathParams) {
    Object.keys(pathParams).forEach(param => {
      pathToGo = pathToGo.replace(`:${param}`, pathParams[param])
    })
  }

  return pathToGo;
}

export default AppRoutes;