import { ThunkAction } from 'redux-thunk';
import { ApplicationState } from "../";
import * as reportsService from "../../services/reportsService";
import { actionCreators as sharedActions, TApiCallAction } from "../shared/actions";
import { ProjectTotals } from "../models/Reports/ProjectTotals";
import { EmployeeTotals } from '../models/Reports/EmployeeTotals';

export enum ReportActionTypes {
    PROJECTTOTALS_LOAD_SUCCESS = "PROJECTTOTALS_LOAD_SUCCESS",
    EMPLOYEETOTALS_LOAD_SUCCESS = "EMPLOYEETOTALS_LOAD_SUCCESS",
}

export interface IReportAction {
    type: ReportActionTypes;
}

export interface IProjectTotalsLoadSuccess extends IReportAction {
    type: ReportActionTypes.PROJECTTOTALS_LOAD_SUCCESS;
    total: number;
    projects: Array<ProjectTotals>;
    filter: any;
    daterange: any;
}

export interface IEmployeeTotalsLoadSuccess extends IReportAction {
    type: ReportActionTypes.EMPLOYEETOTALS_LOAD_SUCCESS;
    total: number;
    employees: Array<EmployeeTotals>;
    filter: any;
    daterange: any;
}

function loadProjectTotalsSuccess(total: number, projects: Array<ProjectTotals>, filter: any, daterange: any): IProjectTotalsLoadSuccess {
    return {
        type: ReportActionTypes.PROJECTTOTALS_LOAD_SUCCESS,
        total,
        projects,
        filter,
        daterange
    }
}

function loadEmployeeTotalsSuccess(total: number, employees: Array<EmployeeTotals>, filter: any, daterange: any): IEmployeeTotalsLoadSuccess {
    return {
        type: ReportActionTypes.EMPLOYEETOTALS_LOAD_SUCCESS,
        total,
        employees,
        filter,
        daterange
    }
}
export type TReportAction = IProjectTotalsLoadSuccess | IEmployeeTotalsLoadSuccess | TApiCallAction;

export const actionCreators = {
    getProjectTotals: (filter?: string, orderby?: string, filterValues?: any, daterange?: any): ThunkAction<Promise<void>, ApplicationState, {}, TReportAction> => async (dispatch, getState): Promise<void> => {
        dispatch(loadProjectTotalsSuccess(0, [], filterValues, daterange));
        dispatch(sharedActions.beginApiCall());
        return await reportsService.getProjectTotals(daterange.start, daterange.end, filter, orderby)
            .then(data => {
                dispatch(loadProjectTotalsSuccess(data.total, data.projects, filterValues, daterange));
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
            });
    },
    getEmployeeTotals: (filter?: string, orderby?: string, filterValues?: any, daterange?: any): ThunkAction<Promise<void>, ApplicationState, {}, TReportAction> => async (dispatch, getState): Promise<void> => {
        dispatch(loadEmployeeTotalsSuccess(0, [], filterValues, daterange));
        dispatch(sharedActions.beginApiCall());
        return await reportsService.getEmployeeTotals(daterange.start, daterange.end, filter, orderby)
            .then(data => {
                dispatch(loadEmployeeTotalsSuccess(data.total, data.employees, filterValues, daterange));
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
            });
    }
};
