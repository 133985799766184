import { handleResponse, handleError, defaultSecureHeaders,getFilterAndSort } from "./apiUtils";
import { TeamMember } from "../store/models/TeamMember";
import { store } from "../App";
import { PagedResults } from "../store/models/PagedResults";

export async function getAll(accountid:string, projectid:string, filter?:string, orderby?:string):Promise<PagedResults<TeamMember>> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + "/projects/" + projectid + "/teammembers" + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function createMultiple(accountid:string, projectid:string, employeeids:string[]):Promise<any> {
    const state = store.getState();
    return fetch(state.appSettings.apiBaseUrl + accountid + "/projects/" + projectid + "/teammembers", {
      method: "POST", 
      headers: await defaultSecureHeaders(),
      body: JSON.stringify({
        employeeids
      })
    })
      .then(handleResponse)
      .catch(handleError);
  }
  
  export async function deleteMultiple(accountid:string, projectid:string, employeeids:string[]):Promise<any> {
    const state = store.getState();
    return fetch(state.appSettings.apiBaseUrl + accountid + "/projects/" + projectid + "/teammembers", { 
      method: "DELETE",
      headers: await defaultSecureHeaders(),
      body: JSON.stringify({
        employeeids
      })
    })
    .then(handleResponse)
    .catch(handleError);
  }