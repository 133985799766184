import { handleResponse, handleError, defaultSecureHeaders} from "./apiUtils";
import { store } from "../App";
import { UserSetting } from "../store/user/state";

export async function getinfo() {
  const state = store.getState();
  
  return fetch(state.appSettings.apiBaseUrl + "account/info", {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
};

export async function updateSettings(userSettings:UserSetting[]):Promise<UserSetting[]> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + "user/settings", {
    method: "POST", 
    headers: await defaultSecureHeaders(),
    body: JSON.stringify(userSettings)
  })
    .then(handleResponse)
    .catch(handleError);
};

export async function register(firstname:string, username:string, password: string):Promise<boolean> {
  const state = store.getState();

  return fetch(state.appSettings.apiBaseUrl + "account/register", {
    method: "POST", 
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json" 
    },
    body: JSON.stringify({firstname:firstname, email: username, password: password})
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function confirm(username:string, token: string):Promise<boolean> {
  const state = store.getState();

  return fetch(state.appSettings.apiBaseUrl + "account/confirm", {
    method: "POST", 
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json" 
    },
    body: JSON.stringify({email: username, token: token})
  })
    .then(handleResponse)
    .catch(handleError);
}