import { ContactLookupItem } from "./Contact";
import { colors} from "@material-ui/core";
import i18n from "../../i18n";

export type ProjectListItem = {
    id: string;
    name: string;
    code: string;
    companyId: string;
    companyName: string;
    customerId: string | null;
    customerName: string;
    contactId: string | null;
    contactName: string;
    start: Date | null;
    end: Date | null;
    status:ProjectStatus;
    statusRemarks: string;
    notes: string;
    totalMinutes: number;
    totalExpenses: number;
    imageCount: number;
}

export enum ProjectStatus {
    NEW = "NEW",
    OFFER = "OFFER",
    IN_PROGRESS = "IN_PROGRESS",
    FINISHED = "FINISHED",
    PROFORMA = "PROFORMA",
    INVOICED = "INVOICED",
    ON_HOLD = "ON_HOLD",
    CANCELLED = "CANCELLED",
    ARCHIVED = "ARCHIVED"
}

export type Project = {
    id: string;
    parentId: string | null;
    projectTypeId: string | null;
    projectTypeName: string;
    status:ProjectStatus;
    statusRemarks:string;
    name: string;
    code: string;
    desc: string;
    notes: string;
    location: string;
    companyId: string;
    companyName: string;
    customerId: string | null;
    customerName: string;
    contactId: string | null;
    contactName: string;
    contractPrice:number | null;
    contractMinutes:number | null;
    contractHours:number | null;
    start: Date | null;
    end: Date | null;
    totalMinutes: number;
    totalExpenses: number;
    imageCount: number;
}

export type ProjectLookupItem = {
    id: string;
    name: string;
    ownProject:boolean;
    contacts: ContactLookupItem[];
}

export type ProjectType = {
    id: string;
    name: string;
}

export function getProjectById(projects: Project[], id: string):Project {
    if (projects.length > 0) {
        return projects.find(project => project.id === id) || newProject();
    }
    return newProject();
}

export function newProject():Project {
    return {
    id: "",
    parentId: null,
    projectTypeId: null,
    projectTypeName:"",
    status: ProjectStatus.NEW,
    statusRemarks: "",
    name:"",
    code:"",
    desc: "",
    notes:"",
    location:"",
    companyId: "",
    companyName: "",
    customerId: null,
    customerName: "",
    contactId: null,
    contactName: "",
    contractMinutes: null,
    contractHours: null,
    contractPrice: null,
    start:null,
    end:null,
    totalMinutes: 0,
    totalExpenses: 0,
    imageCount: 0
}}


export function translateStatus(status:ProjectStatus){
    switch(status)
    {
        case ProjectStatus.NEW : return i18n.t("projects:status.new");
        case ProjectStatus.IN_PROGRESS : return i18n.t("projects:status.inprogress");
        case ProjectStatus.FINISHED : return i18n.t("projects:status.finished");
        case ProjectStatus.INVOICED : return i18n.t("projects:status.invoiced");
        case ProjectStatus.ARCHIVED : return i18n.t("projects:status.archived");
        case ProjectStatus.OFFER : return i18n.t("projects:status.offer");
        case ProjectStatus.ON_HOLD : return i18n.t("projects:status.onhold");
        case ProjectStatus.CANCELLED : return i18n.t("projects:status.cancelled");
        case ProjectStatus.PROFORMA : return i18n.t("projects:status.proforma");
        default : return "";
    }
}

export function statusToColor(status:ProjectStatus){
    switch(status)
    {
        case ProjectStatus.NEW : return colors.grey[500];
        case ProjectStatus.IN_PROGRESS : return colors.indigo[700];
        case ProjectStatus.FINISHED : return colors.orange[700];
        case ProjectStatus.INVOICED : return colors.green[700];
        case ProjectStatus.ARCHIVED : return colors.grey[700];
        case ProjectStatus.OFFER : return colors.brown[500];
        case ProjectStatus.ON_HOLD : return colors.red[300];
        case ProjectStatus.CANCELLED : return colors.red[700];
        case ProjectStatus.PROFORMA : return colors.green[500];        
        default : return colors.common.black;
    }    
}

export type ProjectStatusLookupItem = {
    key:ProjectStatus;
    label: string;
}
export function ProjectStatusLookup() {
    return Object.values(ProjectStatus).map(x => { 
        return { key: x, label: translateStatus(x as ProjectStatus)} 
    });
}
