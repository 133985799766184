import { Image } from "../models/Image";

export interface IImagesState {
    total:number;
    images: Array<Image>;
}

export const initialImagesState  = {
    total:0,
    images:[]
}