import { ThunkAction } from 'redux-thunk'
import * as teamMembersService from "../../services/teamMembersService";
import { TeamMember } from "../models/TeamMember";
import { actionCreators as sharedActions, TApiCallAction } from "../shared/actions";
import { ApplicationState } from "../"

// action types
export enum TeamMemberActionTypes {
    TEAMMEMBERS_LOAD_SUCCESS = "TEAMMEMBERS_LOAD_SUCCESS",
    TEAMMEMBER_CREATE_SUCCESS = "TEAMMEMBER_CREATE_SUCCESS",
    TEAMMEMBERS_DELETE_SUCCESS = "TEAMMEMBERS_DELETE_SUCCESS"
}
  
export interface ITeamMemberAction {
    type: TeamMemberActionTypes;
  }

export interface ITeamMemberLoadSuccess extends ITeamMemberAction {
    type: TeamMemberActionTypes.TEAMMEMBERS_LOAD_SUCCESS;
    projectId: string;
    total:number,
    teamMembers:Array<TeamMember>;
}

export interface ICreateTeamMembersSuccessAction {
    type: TeamMemberActionTypes.TEAMMEMBER_CREATE_SUCCESS;
    projectId: string;
    teamMembers:Array<TeamMember>;
}

export interface IDeleteTeamMembersSuccessAction {
    type: TeamMemberActionTypes.TEAMMEMBERS_DELETE_SUCCESS;
    projectId: string;
    teamMemberids: string[];
}

function loadSuccess(projectId: string, total:number, teamMembers:Array<TeamMember>): ITeamMemberLoadSuccess {
    return {
        type: TeamMemberActionTypes.TEAMMEMBERS_LOAD_SUCCESS,
        projectId,
        total,
        teamMembers
    }
}

function createSuccess(projectId: string, teamMembers:Array<TeamMember>): ICreateTeamMembersSuccessAction {
    return {
        type: TeamMemberActionTypes.TEAMMEMBER_CREATE_SUCCESS,
        projectId,
        teamMembers
    }
}

function deleteSuccess(projectId: string,  teamMemberids:string[]): IDeleteTeamMembersSuccessAction {
    return {
        type: TeamMemberActionTypes.TEAMMEMBERS_DELETE_SUCCESS,
        projectId,
        teamMemberids
    }
}

export type TTeamMemberAction = ITeamMemberLoadSuccess | ICreateTeamMembersSuccessAction | IDeleteTeamMembersSuccessAction | TApiCallAction;

export const actionCreators = {
    load: (projectId:string,filter?:string, orderby?:string): ThunkAction<Promise<void>, ApplicationState, {}, TTeamMemberAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(projectId,0,[]));

        if(projectId){
            dispatch(sharedActions.beginApiCall());
            let accountId = getState().user.accountId;

            return await teamMembersService.getAll(accountId, projectId, filter, orderby)
            .then(data => {
                dispatch(loadSuccess(projectId,data.total, data.items));
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
                });
        }
    },
    save: (projectId:string, teamMemberids:string[]) : ThunkAction<Promise<string[]>, ApplicationState, {}, TTeamMemberAction> => async (dispatch, getState):Promise<string[]> => {
        dispatch(sharedActions.beginApiCall());       
        let accountId = getState().user.accountId;

        return await teamMembersService.createMultiple(accountId, projectId, teamMemberids)
        .then(data => {
            let employeeIds:Array<string> = data.employeeIds || [];

            let members =  getState().employees.employees.filter(x => employeeIds.includes(x.id)).map(x => ({
                employeeId: x.id,
                projectId: data.projectId
              }));

            dispatch(createSuccess(data.projectId, members));
            return employeeIds;
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
        
    },
    delete: (projectId:string, teamMemberids:string[]) : ThunkAction<Promise<string[]>, ApplicationState, {}, TTeamMemberAction> => async (dispatch, getState):Promise<string[]> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        
        return await teamMembersService.deleteMultiple(accountId, projectId, teamMemberids)
        .then(data => {
            let employeeIds:Array<string> = data.employeeIds || [];
            dispatch(deleteSuccess(data.projectId,employeeIds));
            return employeeIds;
        }).catch(error => {
        dispatch(sharedActions.apiCallError(error));
            throw error;
        });

    }    
}