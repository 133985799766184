import { handleResponse, handleError, defaultSecureHeaders,getFilterAndSort, defaultAccessToken } from "./apiUtils";
import { Project, ProjectType } from "../store/models/Project";
import { store } from "../App";
import { PagedResults } from "../store/models/PagedResults";
import { ProjectsFilterValues } from "../store/project/state";

const getFilter = (term: string, values: any): string => {
  let filter = "";
  
  if (values) {
    filter = Object.keys(values as object).filter(p => p !=="key" && values[p] !== undefined && values[p] !== "").map(p => {
      if (p === "status") {
        if (JSON.stringify(values[p]) !== JSON.stringify(ProjectsFilterValues.status)) {
          return p + " in (" + values[p] + ")";
        }
        return null;
      } else {
        return p + " eq " + values[p];
      }
    }).filter(x => x !==null).join(' and ');
  }
  if (term !== "") {
    if (filter.length > 0) filter += ' and ';
    filter = filter + "search ct " + term;
  }
  return filter;
}

export async function getTypes(accountid:string):Promise<Array<ProjectType>> {
  const state = store.getState();
  
  return fetch(state.appSettings.apiBaseUrl + accountid + "/projects/types", {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getById(accountid:string, id:string):Promise<Project> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/projects/" + id, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getAll(accountid:string, search?:string, orderby?:string,filterValues?:any):Promise<PagedResults<Project>> {
  return getByUrl("/projects", accountid, search, orderby, filterValues);
}

export async function getByCurrentUser(accountid:string, search?:string, orderby?:string,filterValues?:any):Promise<PagedResults<Project>> {
  return getByUrl("/your/projects", accountid, search, orderby, filterValues);
}

export async function getByEmployee(accountid:string, employeeid:string, search?:string, orderby?:string,filterValues?:any):Promise<PagedResults<Project>> {
  return getByUrl("/employees/" + employeeid + "/projects", accountid, search, orderby, filterValues);
}

export async function getByCompany(accountid:string, companyid:string, search?:string, orderby?:string,filterValues?:any):Promise<PagedResults<Project>> {
  return getByUrl("/companies/" + companyid + "/projects", accountid, search, orderby, filterValues);
}

export async function getByCustomer(accountid:string, customerid:string, search?:string, orderby?:string,filterValues?:any):Promise<PagedResults<Project>> {
  return getByUrl("/customers/" + customerid + "/projects", accountid, search, orderby, filterValues);
}

export async function getLatestByCurrentUser(accountid:string):Promise<Array<Project>> {
  return getByUrl("/your/projects/latest",accountid);
}

async function getByUrl(url:string, accountid:string, search?:string, orderby?:string,filterValues?:any):Promise<any> {
  const state = store.getState();
  const filter = getFilter(search || "", filterValues);
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + url + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getImageThumbUrl(imageid:string):Promise<string> {
  const state = store.getState();
  var token = await defaultAccessToken();
  return state.appSettings.apiBaseUrl + state.user.accountId + "/images/" + imageid + "/thumb?" + token;
}

export async function getImageUrl(imageid:string):Promise<string> {
  const state = store.getState();
  var token = await defaultAccessToken();
  return state.appSettings.apiBaseUrl + state.user.accountId + "/images/" + imageid + "?" + token;
}

export async function create(accountid:string, project:Project):Promise<Project> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/projects", {
    method: "POST", 
    headers: await defaultSecureHeaders(),
    body: JSON.stringify(project)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function update(accountid:string, project:Project):Promise<Project> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/projects/" + project.id, {
    method: "POST", 
    headers: await defaultSecureHeaders(),
    body: JSON.stringify(project)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteMultiple(accountid:string, projectids:string[]):Promise<string[]> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/projects", { 
    method: "DELETE",
    headers: await defaultSecureHeaders(),
    body: JSON.stringify({
      projectids
    })
  })
  .then(handleResponse)
  .catch(handleError);
}