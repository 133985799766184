import { ThunkAction } from 'redux-thunk';
import { Expense } from "../models/Expense";
import * as expensesService from "../../services/expensesService";
import { actionCreators as sharedActions,TApiCallAction } from "../shared/actions";
import { ApplicationState } from "../"

export enum ExpenseActionTypes {
    EXPENSES_LOAD_SUCCESS = "EXPENSES_LOAD_SUCCESS",
    EXPENSES_DOWNLOAD_SUCCESS = "EXPENSES_DOWNLOAD_SUCCESS",
    EXPENSES_DELETE_SUCCESS = "EXPENSES_DELETE_SUCCESS"
}

export interface IExpenseAction {
    type: ExpenseActionTypes;
  }

export interface IExpenseLoadSuccess extends IExpenseAction {
    type: ExpenseActionTypes.EXPENSES_LOAD_SUCCESS;
    total: number;
    expenses:Array<Expense>;
}

export interface IExpenseDownLoadSuccess extends IExpenseAction {
    type: ExpenseActionTypes.EXPENSES_DOWNLOAD_SUCCESS;
}

export interface IDeleteExpenseSuccessAction extends IExpenseAction {
    type: ExpenseActionTypes.EXPENSES_DELETE_SUCCESS;
    expenseids: string[];
}

function loadSuccess(total: number, expenses:Array<Expense>, filter:any): IExpenseLoadSuccess {
    return {
        type: ExpenseActionTypes.EXPENSES_LOAD_SUCCESS,
        total,
        expenses
    }
}

function downloadSuccess(): IExpenseDownLoadSuccess {
    return {
        type: ExpenseActionTypes.EXPENSES_DOWNLOAD_SUCCESS
    }
}

function deleteSuccess(expenseids:string[]): IDeleteExpenseSuccessAction {
    return {
        type: ExpenseActionTypes.EXPENSES_DELETE_SUCCESS,
        expenseids
    }
}

export type TExpenseAction = IExpenseLoadSuccess | IExpenseDownLoadSuccess | IDeleteExpenseSuccessAction | TApiCallAction;

export const actionCreators = {
    loadByProject: (projectid:string,filter?:string, orderby?:string, filterValues?:any): ThunkAction<Promise<void>, ApplicationState, {}, TExpenseAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(0, [],filterValues));

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;

        return await expensesService.getByProject(accountId,projectid,filter,orderby)
        .then(data => {
            dispatch(loadSuccess(data.total, data.items,filterValues));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },
    loadExcelByProject: (projectid:string): ThunkAction<Promise<void>, ApplicationState, {}, TExpenseAction> => async (dispatch, getState):Promise<void> => {

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await expensesService.getExcelByProject(accountId,projectid)
        .then(data => {
            dispatch(downloadSuccess());
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },   
    delete: (projectid:string, expenseids:string[]) : ThunkAction<Promise<void>, ApplicationState, {}, TExpenseAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        
        return await expensesService.deleteMultiple(accountId,projectid,expenseids)
        .then(data => {
            dispatch(sharedActions.enqueueSnackbar({message: `Deleted ${data.length} expense record(s)`}));
            dispatch(deleteSuccess(data));
        }).catch(error => {
            dispatch(sharedActions.enqueueSnackbar({message: `There was a problem deleting your expenses`, options: {variant: 'error'}}));
            dispatch(sharedActions.apiCallError(error));
            throw error;
        });

    }
}