import { Employee } from "../models/Employee";
import { actionCreators as sharedActions, TApiCallAction } from "../shared/actions";
import { ThunkAction } from 'redux-thunk'
import * as employeesService from "../../services/employeesService";
import { ApplicationState } from "../"
import { PagedResults } from "../models/PagedResults";

// action types
export enum EmployeeActionTypes {
    EMPLOYEES_LOAD_SUCCESS = "EMPLOYEES_LOAD_SUCCESS",
    EMPLOYEE_CREATE_SUCCESS = "EMPLOYEE_CREATE_SUCCESS",
    EMPLOYEE_UPDATE_SUCCESS = "EMPLOYEE_UPDATE_SUCCESS",
    EMPLOYEES_DELETE_SUCCESS = "EMPLOYEES_DELETE_SUCCESS",
    EMPLOYEES_SETFAV_SUCCESS = "EMPLOYEES_SETFAV_SUCCESS"
}
  

export interface IEmployeeAction {
    type: EmployeeActionTypes;
  }

export interface IEmployeeLoadSuccess extends IEmployeeAction {
    type: EmployeeActionTypes.EMPLOYEES_LOAD_SUCCESS;
    total:number;
    employees:Array<Employee>;
}

export interface ICreateEmployeeSuccessAction {
    type: EmployeeActionTypes.EMPLOYEE_CREATE_SUCCESS;
    employee: Employee;
}
  
export interface IUpdateEmployeeSuccessAction {
    type: EmployeeActionTypes.EMPLOYEE_UPDATE_SUCCESS;
    employee: Employee;
}

export interface IDeleteEmployeeSuccessAction {
    type: EmployeeActionTypes.EMPLOYEES_DELETE_SUCCESS;
    employeeids: string[];
}

export interface ISetFavEmployeeSuccessAction {
    type: EmployeeActionTypes.EMPLOYEES_SETFAV_SUCCESS;
    employeeids: string[];
    setfav: boolean;
}

function loadSuccess(total:number, employees:Array<Employee>): IEmployeeLoadSuccess {
    return {
        type: EmployeeActionTypes.EMPLOYEES_LOAD_SUCCESS,
        total,
        employees
    }
}

function createSuccess(employee:Employee): ICreateEmployeeSuccessAction {
    return {
        type: EmployeeActionTypes.EMPLOYEE_CREATE_SUCCESS,
        employee
    }
}

function updateSuccess(employee:Employee): IUpdateEmployeeSuccessAction {
    return {
        type: EmployeeActionTypes.EMPLOYEE_UPDATE_SUCCESS,
        employee
    }
}

function deleteSuccess(employeeids:string[]): IDeleteEmployeeSuccessAction {
    return {
        type: EmployeeActionTypes.EMPLOYEES_DELETE_SUCCESS,
        employeeids
    }
}

function setFavSuccess(employeeids:string[], setfav:boolean): ISetFavEmployeeSuccessAction {
    return {
        type: EmployeeActionTypes.EMPLOYEES_SETFAV_SUCCESS,
        employeeids,
        setfav
    }
}

export type TEmployeeAction = IEmployeeLoadSuccess | ICreateEmployeeSuccessAction | IUpdateEmployeeSuccessAction | IDeleteEmployeeSuccessAction | ISetFavEmployeeSuccessAction | TApiCallAction;

const loadEmployeesByFunc = (func:Promise<PagedResults<Employee>>): ThunkAction<Promise<void>, ApplicationState, {}, TEmployeeAction> => async (dispatch, getState):Promise<void> => {
    dispatch(loadSuccess(0,[]));
    dispatch(sharedActions.beginApiCall());
    return await func
    .then(data => {
        dispatch(loadSuccess(data.total,data.items));
    }).catch(error => {
        dispatch(sharedActions.apiCallError(error));
        throw error;
        });
};

export const actionCreators = {
    loadEmployee: (id:string): ThunkAction<Promise<void>, ApplicationState, {}, TEmployeeAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(0,[]));

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await employeesService.getById(accountId, id)
        .then(data => {
            dispatch(loadSuccess(1, [data]));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },
    load: (filter?:string, orderby?:string): ThunkAction<Promise<void>, ApplicationState, {}, TEmployeeAction> => async (dispatch, getState):Promise<void> => {
        const accountId = getState().user.accountId;
        const func = employeesService.getAll(accountId, filter, orderby);
        return dispatch(loadEmployeesByFunc(func));
    },
    loadByCompany: (companyid:string,filter?:string, orderby?:string): ThunkAction<Promise<void>, ApplicationState, {}, TEmployeeAction> => async (dispatch, getState):Promise<void> => {
        const accountId = getState().user.accountId;
        const func = employeesService.getByCompany(accountId,companyid, filter, orderby);
        return dispatch(loadEmployeesByFunc(func));
    },  
    save: (employee:Employee) : ThunkAction<Promise<string>, ApplicationState, {}, TEmployeeAction> => async (dispatch, getState):Promise<string> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        if(employee.id){
            return await employeesService.update(accountId, employee)
            .then(data => {
                dispatch(updateSuccess(data));
                return data.id
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
                });
        }else{
            return await employeesService.create(accountId, employee)
            .then(data => {
                dispatch(createSuccess(data));
                return data.id
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
                });
        }
    },
    delete: (employeeids:string[]) : ThunkAction<Promise<void>, ApplicationState, {}, TEmployeeAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        
        return await employeesService.deleteMultiple(accountId, employeeids)
        .then(data => {
            dispatch(deleteSuccess(data));
        }).catch(error => {
        dispatch(sharedActions.apiCallError(error));
            throw error;
        });
    },
    setfav: (employeeids:string[], setfav:boolean) : ThunkAction<Promise<void>, ApplicationState, {}, TEmployeeAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        
        return await employeesService.setfav(accountId, employeeids, setfav)
        .then(data => {
            dispatch(setFavSuccess(data, setfav));
        }).catch(error => {
        dispatch(sharedActions.apiCallError(error));
            throw error;
        });
    }   
}