import { handleResponse, handleError, defaultSecureHeaders, getFilterAndSort } from "./apiUtils";
import { store } from "../App";
import { ProjectTotalsReport } from "../store/models/Reports/ProjectTotalsReport";
import { EmployeeTotalsReport } from "../store/models/Reports/EmployeeTotalsReport";
import moment from "moment";

export async function getProjectTotals(startDate:Date, endDate:Date, filter?:string, orderby?:string):Promise<ProjectTotalsReport> {
  const state = store.getState();
  const start =  moment(startDate).utc(false).format("YYYY-MM-DDTHH:mm:ss");
  const end =  moment(endDate).utc(false).format("YYYY-MM-DDTHH:mm:ss");
  let qs = getFilterAndSort(filter,orderby);
  qs = qs + (qs.length > 0 ? "&" : "?") +"start=" + start + "&end=" + end;
  
  return fetch(state.appSettings.apiBaseUrl + state.user.accountId + "/reports/projecttotals" + qs , {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}
 
export async function getEmployeeTotals(startDate:Date, endDate:Date, filter?:string, orderby?:string):Promise<EmployeeTotalsReport> {
  const state = store.getState();
  const start =  moment(startDate).utc(false).format("YYYY-MM-DDTHH:mm:ss");
  const end =  moment(endDate).utc(false).format("YYYY-MM-DDTHH:mm:ss");
  let qs = getFilterAndSort(filter,orderby);
  qs = qs + (qs.length > 0 ? "&" : "?") +"start=" + start + "&end=" + end;
  
  return fetch(state.appSettings.apiBaseUrl + state.user.accountId + "/reports/employeetotals" + qs , {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}