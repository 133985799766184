import { ThunkAction } from 'redux-thunk'
import * as projectsService from "../../services/projectsService";
import { Project,ProjectType } from "../models/Project";
import { actionCreators as sharedActions, TApiCallAction } from "../shared/actions";
import { ApplicationState } from "../"
import { PagedResults } from '../models/PagedResults';

// action types
export enum ProjectActionTypes {
    PROJECTS_LOAD_SUCCESS = "PROJECTS_LOAD_SUCCESS",
    PROJECTTYPES_LOAD_SUCCESS = "PROJECTTYPES_LOAD_SUCCESS",
    PROJECT_CREATE_SUCCESS = "PROJECT_CREATE_SUCCESS",
    PROJECT_UPDATE_SUCCESS = "PROJECT_UPDATE_SUCCESS",
    PROJECT_DELETE_SUCCESS = "PROJECTS_DELETE_SUCCESS"
}
  
export interface IProjectAction {
    type: ProjectActionTypes;
  }

export interface IProjectLoadSuccess extends IProjectAction {
    type: ProjectActionTypes.PROJECTS_LOAD_SUCCESS;
    total:number;
    projects:Array<Project>;
    filter: any;
}

export interface IProjectTypesLoadSuccess extends IProjectAction {
    type: ProjectActionTypes.PROJECTTYPES_LOAD_SUCCESS;
    projectTypes:Array<ProjectType>;
}


export interface ICreateProjectSuccessAction {
    type: ProjectActionTypes.PROJECT_CREATE_SUCCESS;
    project: Project;
}
  
export interface IUpdateProjectSuccessAction {
    type: ProjectActionTypes.PROJECT_UPDATE_SUCCESS;
    project: Project;
}

export interface IDeleteProjectSuccessAction {
    type: ProjectActionTypes.PROJECT_DELETE_SUCCESS;
    projectids: string[];
}

function loadSuccess(total:number, projects:Array<Project>, filter:any): IProjectLoadSuccess {
    return {
        type: ProjectActionTypes.PROJECTS_LOAD_SUCCESS,
        total,
        projects,
        filter
    }
}

function loadTypesSuccess( projectTypes:Array<ProjectType>): IProjectTypesLoadSuccess {
    return {
        type: ProjectActionTypes.PROJECTTYPES_LOAD_SUCCESS,
        projectTypes
    }
}

function createSuccess(project:Project): ICreateProjectSuccessAction {
    return {
        type: ProjectActionTypes.PROJECT_CREATE_SUCCESS,
        project
    }
}

function updateSuccess(project:Project): IUpdateProjectSuccessAction {
    return {
        type: ProjectActionTypes.PROJECT_UPDATE_SUCCESS,
        project
    }
}

function deleteSuccess(projectids:string[]): IDeleteProjectSuccessAction {
    return {
        type: ProjectActionTypes.PROJECT_DELETE_SUCCESS,
        projectids
    }
}

export type TProjectAction = IProjectLoadSuccess | IProjectTypesLoadSuccess | ICreateProjectSuccessAction | IUpdateProjectSuccessAction | IDeleteProjectSuccessAction | TApiCallAction;

const loadProjectsByFunc = (func:Promise<PagedResults<Project>>, filterValues?:any): ThunkAction<Promise<void>, ApplicationState, {}, TProjectAction> => async (dispatch, getState):Promise<void> => {
    dispatch(loadSuccess(0,[],filterValues));
    dispatch(sharedActions.beginApiCall());
    return await func
    .then(data => {
        dispatch(loadSuccess(data.total,data.items,filterValues));
    }).catch(error => {
        dispatch(sharedActions.apiCallError(error));
        throw error;
        });
};

export const actionCreators = {
    loadProject: (id:string): ThunkAction<Promise<void>, ApplicationState, {}, TProjectAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await projectsService.getById(accountId, id)
        .then(data => {
            dispatch(loadSuccess(1,[data], null));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },
    load: (search?:string, orderby?:string, filterValues?:any): ThunkAction<Promise<void>, ApplicationState, {}, TProjectAction> => async (dispatch, getState):Promise<void> => {
        const accountId = getState().user.accountId;
        const func = projectsService.getAll(accountId, search, orderby, filterValues);       
        return dispatch(loadProjectsByFunc(func, filterValues));
    },
    loadByCurrentUser: (search?:string, orderby?:string, filterValues?:any): ThunkAction<Promise<void>, ApplicationState, {}, TProjectAction> => async (dispatch, getState):Promise<void> => {
        const accountId = getState().user.accountId;
        const func = projectsService.getByCurrentUser(accountId, search, orderby, filterValues)
        return dispatch(loadProjectsByFunc(func, filterValues));
    },  
    loadLatestByCurrentUser: (): ThunkAction<Promise<Project[]>, ApplicationState, {}, TProjectAction> => async (dispatch, getState):Promise<Project[]> => {
        const accountId = getState().user.accountId;
        dispatch(sharedActions.beginApiCall());
        return await projectsService.getLatestByCurrentUser(accountId)
        .then(data => {
            return data;
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
        });
    },      
    loadByEmployee: (employeeid:string, search?:string, orderby?:string, filterValues?:any): ThunkAction<Promise<void>, ApplicationState, {}, TProjectAction> => async (dispatch, getState):Promise<void> => {
        const accountId = getState().user.accountId;
        const func = projectsService.getByEmployee(accountId, employeeid, search, orderby, filterValues);       
        return dispatch(loadProjectsByFunc(func, filterValues));
    },
    loadByCompany: (companyid:string,search?:string, orderby?:string, filterValues?:any): ThunkAction<Promise<void>, ApplicationState, {}, TProjectAction> => async (dispatch, getState):Promise<void> => {
        const accountId = getState().user.accountId;
        const func = projectsService.getByCompany(accountId,companyid, search, orderby, filterValues)
        return dispatch(loadProjectsByFunc(func, filterValues));
    },  
    loadByCustomer: (customerid:string,search?:string, orderby?:string, filterValues?:any): ThunkAction<Promise<void>, ApplicationState, {}, TProjectAction> => async (dispatch, getState):Promise<void> => {
        const accountId = getState().user.accountId;
        const func = projectsService.getByCustomer(accountId,customerid, search, orderby, filterValues)
        return dispatch(loadProjectsByFunc(func, filterValues));
    },  
    loadTypes: (): ThunkAction<Promise<void>, ApplicationState, {}, TProjectAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadTypesSuccess([]));
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await projectsService.getTypes(accountId)
        .then(data => {
            dispatch(loadTypesSuccess(data));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },
    save: (project:Project) : ThunkAction<Promise<string>, ApplicationState, {}, TProjectAction> => async (dispatch, getState):Promise<string> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        if(project.id){
            return await projectsService.update(accountId, project)
            .then(data => {
                dispatch(updateSuccess(data));
                return data.id;
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
                });
        }else{
            return await projectsService.create(accountId, project)
            .then(data => {
                dispatch(createSuccess(data));
                return data.id;
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
                });
        }
    },
    delete: (projectids:string[]) : ThunkAction<Promise<void>, ApplicationState, {}, TProjectAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        
        return await projectsService.deleteMultiple(accountId, projectids)
        .then(data => {
            dispatch(deleteSuccess(data));
        }).catch(error => {
        dispatch(sharedActions.apiCallError(error));
            throw error;
        });

    }    
}