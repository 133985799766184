import { Reducer } from "redux";
import { IImagesState, initialImagesState } from "./state";
import { ImageActionTypes, TImageAction } from "./actions";

export const reducer:Reducer<IImagesState> = (prevstate:IImagesState = initialImagesState, action:TImageAction) : IImagesState => {
    switch(action.type){
        case ImageActionTypes.IMAGES_LOAD_SUCCESS:
            return {...prevstate,  total: action.total, images: action.images};                                      
        default:
            return prevstate;
    }
}
