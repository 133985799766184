import React, { Suspense, useState, useEffect } from "react";
import { renderRoutes, RouteConfig } from 'react-router-config';
import { makeStyles } from "@material-ui/core/styles";
import NavMenuSecure from "./NavMenuSecure";
import NavBar from './NavBar';
import { LinearProgress, Theme } from "@material-ui/core";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { actionCreators as userActions } from "../../store/user/actions";
import { Redirect } from "react-router";
import { NormalizedRole } from "../../store/models/Role";
import AppRoutes from "../../routeConstants";

const useStyles = makeStyles((theme:Theme) => ({
    container: {
        minHeight: '100vh',
        display: 'flex',
        '@media all and (-ms-high-contrast:none)': {
          height: 0 // IE11 fix
        }
      },
      content: {
        paddingTop: 64,
        display: 'flex',
        flexDirection:'column',
        flexGrow:1,
        maxWidth: '100%',
        overflowX: 'hidden',
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 256
        },
        [theme.breakpoints.down('xs')]: {
          paddingTop: 56
        }
      }
}));

interface SecureLayoutLayoutProps {
    route?:any;
    isLoading: boolean;
    isSignedIn: boolean;
    loadUser: () => Promise<void>;
    loadUserData: () => Promise<void>;
    role: NormalizedRole;
}

const SecureLayout: React.FC<SecureLayoutLayoutProps> = ({ isSignedIn, isLoading, route, loadUser, loadUserData, role,  ...rest}) => {
  let classes = useStyles(rest);
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  useEffect(() => {
    if(isSignedIn === false){
      loadUser();
    }else{
      loadUserData();
    }
   }, [isSignedIn]);

   const checkRolesOnRoutes = (routes:RouteConfig[]) =>{
      return routes.map(x => {
        if(x.roles !== undefined && !x.roles.includes(role)) x.component = () => <Redirect to={AppRoutes.ERRORS_403} />;
        return x;
      });
   }

  return isLoading ? 
  (<></>) : isSignedIn ? 
  ( <>
    <NavMenuSecure onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      <NavBar onMobileClose={() => setOpenNavBarMobile(false)} openMobile={openNavBarMobile} />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(checkRolesOnRoutes(route.routes))}
          </Suspense>
        </div>
      </div>
    </>
  ) : 
  (<></>)
  };

export default connect(
  (state: ApplicationState) => {
    return {
      isLoading: state.user.loading,
      isSignedIn: state.user.authUser !== null,
      role: state.user.role
    };
  },
  {
    loadUser: userActions.loadUser,
    loadUserData: userActions.loadUserData
  }
)(SecureLayout);