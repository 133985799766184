import { Reducer } from "redux";
import { IEmployeesState, initialEmployeesState } from "./state";
import { EmployeeActionTypes, TEmployeeAction } from "./actions";

export const reducer:Reducer<IEmployeesState> = (prevstate:IEmployeesState = initialEmployeesState, action:TEmployeeAction) : IEmployeesState => {
    switch(action.type){
        case EmployeeActionTypes.EMPLOYEES_LOAD_SUCCESS:
            return {...prevstate, total: action.total, employees: action.employees};
        case EmployeeActionTypes.EMPLOYEE_CREATE_SUCCESS:
            return {...prevstate, employees: [...prevstate.employees,{ ...action.employee }]};
        case EmployeeActionTypes.EMPLOYEE_UPDATE_SUCCESS:
            return {...prevstate, employees: prevstate.employees.map(employee =>
                employee.id === action.employee.id ? action.employee : employee
            )};  
        case EmployeeActionTypes.EMPLOYEES_DELETE_SUCCESS:
            return {...prevstate, employees: prevstate.employees.filter(employee =>
                action.employeeids.indexOf(employee.id) === -1
            )};   
        case EmployeeActionTypes.EMPLOYEES_SETFAV_SUCCESS:
            return {...prevstate, employees: prevstate.employees.map(employee =>
                action.employeeids.indexOf(employee.id) === -1 ? employee : {...employee, isFav: action.setfav }
            )};                                                  
        default:
            return prevstate;
    }
}
