import { combineReducers} from 'redux';
import { IAppSettings }  from "./app/state";
import { IApiState }  from "./shared/state";
import { IUserState }  from "./user/state";
import { ICountriesState }  from "./country/state";
import { ICompaniesState }  from "./company/state";
import { IEmployeesState }  from "./employee/state";
import { IContactsState }  from "./contact/state";
import { ICustomersState }  from "./customer/state";
import { IProjectsState }  from "./project/state";
import { ITeamMembersState }  from "./teammember/state";
import { ITimeEntriesState }  from "./timeentry/state";
import { IExpensesState }  from "./expense/state";
import { IImagesState }  from "./image/state";
import { IReportsState }  from "./reports/state";
import { appSettingsReducer as appSettings } from "./app/reducers";
import { reducer as apiState } from "./shared/reducers";
import { reducer as user } from "./user/reducers";
import { reducer as countries } from "./country/reducers";
import { reducer as companies } from "./company/reducers";
import { reducer as employees } from "./employee/reducers";
import { reducer as customers } from "./customer/reducers";
import { reducer as contacts } from "./contact/reducers";
import { reducer as projects } from "./project/reducers";
import { reducer as teammembers } from "./teammember/reducers";
import { reducer as timeentries } from "./timeentry/reducers";
import { reducer as expenses } from "./expense/reducers";
import { reducer as images } from "./image/reducers";
import { reducer as reports } from "./reports/reducers";

// The top-level state object
export interface ApplicationState {
  appSettings: IAppSettings;
  apiState: IApiState;
  user: IUserState;
  countries: ICountriesState;
  companies: ICompaniesState;
  employees: IEmployeesState;
  customers: ICustomersState;
  contacts: IContactsState;
  projects: IProjectsState;
  teammembers: ITeamMembersState;
  timeentries: ITimeEntriesState;
  expenses: IExpensesState;
  images: IImagesState;
  reports: IReportsState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  appSettings,
  apiState,
  user,
  countries,
  companies,
  employees,
  customers,
  contacts,
  projects,
  teammembers,
  timeentries,
  expenses,
  images,
  reports
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
export interface PromiseAppThunkAction<TAction> {
  (dispatch: (action: TAction) =>  void, getState: () => ApplicationState): void;
}
