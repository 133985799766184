import { TeamMember } from "../models/TeamMember";

export interface ITeamMembersState {
    total:number;
    teammembers: Array<TeamMember>;
}

export const initialTeamMembersState = {
    total:0,
    teammembers:[]
}