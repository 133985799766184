import { Reducer } from "redux";
import { ITeamMembersState, initialTeamMembersState } from "./state";
import { TeamMemberActionTypes, TTeamMemberAction } from "./actions";

export const reducer:Reducer<ITeamMembersState> = (prevstate:ITeamMembersState = initialTeamMembersState, action:TTeamMemberAction) : ITeamMembersState => {
    switch(action.type){
        case TeamMemberActionTypes.TEAMMEMBERS_LOAD_SUCCESS:
            return {...prevstate, total: action.total, teammembers: action.teamMembers};
        case TeamMemberActionTypes.TEAMMEMBER_CREATE_SUCCESS:
            return {...prevstate,  total: prevstate.total + action.teamMembers.length, teammembers: [...action.teamMembers, ...prevstate.teammembers]};
        case TeamMemberActionTypes.TEAMMEMBERS_DELETE_SUCCESS:
            return {...prevstate, total: prevstate.total - action.teamMemberids.length, teammembers: prevstate.teammembers.filter(teamMember =>
                action.teamMemberids.indexOf(teamMember.employeeId) === -1
            )};                                        
        default:
            return prevstate;
    }
}
