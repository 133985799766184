import React, { Suspense } from "react";
import { renderRoutes } from 'react-router-config';
import { makeStyles } from "@material-ui/core/styles";
import NavMenuAnonymous from "./NavMenuAnonymous";
import { LinearProgress, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme:Theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
        height: 0 // IE11 fix
    }
    },
    content: {
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.down('xs')]: {
        paddingTop: 56
    }
  }
}));

interface UnsecureLayoutProps {
    route?: any;
}

const UnsecureLayout: React.FC<UnsecureLayoutProps> = ({route,...rest}) => {
  let classes = useStyles(rest);

  return (
    <>
      <NavMenuAnonymous />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default UnsecureLayout;
