import { ThunkAction } from 'redux-thunk'
import * as contactsService from "../../services/contactsService";
import { Contact } from "../models/Contact";
import { actionCreators as sharedActions, TApiCallAction } from "../shared/actions";
import { ApplicationState } from "../"

// action types
export enum ContactActionTypes {
    CONTACTS_LOAD_SUCCESS = "CONTACTS_LOAD_SUCCESS",
    CONTACT_CREATE_SUCCESS = "CONTACT_CREATE_SUCCESS",
    CONTACT_UPDATE_SUCCESS = "CONTACT_UPDATE_SUCCESS",
    CONTACT_DELETE_SUCCESS = "CONTACTS_DELETE_SUCCESS"
}
  
export interface IContactAction {
    type: ContactActionTypes;
  }

export interface IContactLoadSuccess extends IContactAction {
    type: ContactActionTypes.CONTACTS_LOAD_SUCCESS;
    customerId: string;
    total:number;
    contacts:Array<Contact>;
}

export interface ICreateContactSuccessAction {
    type: ContactActionTypes.CONTACT_CREATE_SUCCESS;
    customerId: string;
    contact: Contact;
}
  
export interface IUpdateContactSuccessAction {
    type: ContactActionTypes.CONTACT_UPDATE_SUCCESS;
    customerId: string;
    contact: Contact;
}

export interface IDeleteContactSuccessAction {
    type: ContactActionTypes.CONTACT_DELETE_SUCCESS;
    customerId: string;
    contactids: string[];
}
function loadSuccess(customerId: string, total:number, contacts:Array<Contact>): IContactLoadSuccess {
    return {
        type: ContactActionTypes.CONTACTS_LOAD_SUCCESS,
        customerId,
        total,
        contacts
    }
}

function createSuccess(customerId: string,contact:Contact): ICreateContactSuccessAction {
    return {
        type: ContactActionTypes.CONTACT_CREATE_SUCCESS,
        customerId,
        contact
    }
}

function updateSuccess(customerId: string,contact:Contact): IUpdateContactSuccessAction {
    return {
        type: ContactActionTypes.CONTACT_UPDATE_SUCCESS,
        customerId,
        contact
    }
}

function deleteSuccess(customerId: string,contactids:string[]): IDeleteContactSuccessAction {
    return {
        type: ContactActionTypes.CONTACT_DELETE_SUCCESS,
        customerId,
        contactids
    }
}

export type TContactAction = IContactLoadSuccess | ICreateContactSuccessAction | IUpdateContactSuccessAction | IDeleteContactSuccessAction | TApiCallAction;

export const actionCreators = {
    load: (customerId:string,filter?:string, orderby?:string): ThunkAction<Promise<void>, ApplicationState, {}, TContactAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(customerId,0,[]));

        if(customerId){
            dispatch(sharedActions.beginApiCall());
            let accountId = getState().user.accountId;

            return await contactsService.getAll(accountId, customerId, filter, orderby)
            .then(data => {
                dispatch(loadSuccess(customerId,data.total, data.items));
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
                });
        }
    },
    save: (contact:Contact) : ThunkAction<Promise<void>, ApplicationState, {}, TContactAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        if(contact.id){
            return await contactsService.update(accountId, contact.customerId, contact)
            .then(data => {
                dispatch(updateSuccess(data.customerId,data));
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
                });
        }else{
            return await contactsService.create(accountId, contact.customerId, contact)
            .then(data => {
                dispatch(createSuccess(data.customerId,data));
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
                });
        }
    },
    delete: (customerId:string, contactids:string[]) : ThunkAction<Promise<void>, ApplicationState, {}, TContactAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        
        return await contactsService.deleteMultiple(accountId, customerId, contactids)
        .then(data => {
            dispatch(deleteSuccess(customerId,data));
        }).catch(error => {
        dispatch(sharedActions.apiCallError(error));
            throw error;
        });

    }    
}