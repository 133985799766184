import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, Theme } from "@material-ui/core/styles";
import { List, Hidden, Drawer, colors, Divider, Avatar, Typography, IconButton, Link, ListItem, Button } from "@material-ui/core";
import MoreIcon from '@material-ui/icons/MoreVert';
import NavConfig from "./navConfig";
import { NavConfigItem } from "./navConfig";
import NavItem from "../../components/NavItem";
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { actionCreators as userActions } from "../../store/user/actions";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { translateRole, NormalizedRole } from '../../store/models/Role';
import { TFunction } from 'i18next';
import AppRoutes, { urlAction } from "../../routeConstants";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1
  },
  bottomNavigation: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40
  },
  details: {
    marginLeft: theme.spacing(2)
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200]
  },
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  version: {
    fontSize: 10,
    textAlign: "right",
    padding: theme.spacing(0,2)
  }

}));


const renderNavItems = (items: NavConfigItem[] | undefined, pathname: string, key: string | undefined, depth: number, role: string, t: TFunction) => {
  if (items) {
    return (
      <List key={key}>
        {items.filter(x => x.roles === undefined || x.roles.includes(role)).reduce<React.ReactNode[]>((acc, item) => reduceChildRoutes(acc, item, pathname, depth, role, t), [])}
      </List>
    );
  }
}

const reduceChildRoutes = (acc: React.ReactNode[], item: NavConfigItem, pathname: string, depth: number, role: string, t: TFunction) => {
  if (item.items) {
    const open = item.open || matchPath(pathname, { path: item.href, exact: false });

    acc.push(
      <NavItem depth={depth} icon={item.icon} key={item.href} href="" label={item.label} open={Boolean(open)} title={t(item.title)}>
        {renderNavItems(item.items, pathname, "", depth + 1, role, t)}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem depth={depth} href={item.href} icon={item.icon} key={item.href} label={item.label} title={t(item.title)} />
    );
  }

  return acc;
}

interface NavBarProps {
  className?: string;
  onMobileClose: () => void;
  logout?: () => void;
  openMobile: boolean;
  userName: string;
  role: NormalizedRole;
  accountName: string;
}

const NavBar: React.FC<NavBarProps> = ({ openMobile, onMobileClose, logout, className, userName, role, accountName, ...rest }) => {
  let classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        {NavConfig.map((list) => renderNavItems(list.items, location.pathname, list.subheader, 0, role, t))}
      </nav>

      <div className={classes.bottomNavigation}>
        <ListItem className={classes.item} disableGutters >
          <Button className={classes.button} color="inherit" onClick={logout}><ExitToAppIcon className={classes.icon} /> {t("nav.logout")}</Button>
        </ListItem>
        <Typography variant="subtitle2" className={classes.version}>version: {process.env.REACT_APP_VERSION}</Typography>
      </div>

      <Divider />
      <Link component={RouterLink} to={urlAction(AppRoutes.PROFILE)} underline="none" >
        <div className={classes.profile}>

          <Avatar alt="Person" className={classes.avatar} />
          <div className={classes.details}>

            <Typography variant="h5" color="textPrimary">
              {userName}
            </Typography>

            <Typography variant="body2" color="textPrimary">{translateRole(role)}</Typography>
          </div>
          <IconButton className={classes.moreButton} size="small">
            <MoreIcon />
          </IconButton>
        </div>
      </Link>
      
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default connect(
  (state: ApplicationState) => {
    return {
      userName: state.user.name,
      role: state.user.role,
      accountName: state.user.accountName
    };
  },
  {
    logout: userActions.logout
  }
)(NavBar);
