import { Reducer } from "redux";
import { ICustomersState, initialCustomersState } from "./state";
import { CustomerActionTypes, TCustomerAction } from "./actions";

export const reducer:Reducer<ICustomersState> = (prevstate:ICustomersState = initialCustomersState, action:TCustomerAction) : ICustomersState => {
    switch(action.type){
        case CustomerActionTypes.CUSTOMERS_LOAD_SUCCESS:
            return {...prevstate, total: action.total, customers: action.customers};
        case CustomerActionTypes.CUSTOMER_CREATE_SUCCESS:
            return {...prevstate, customers: [...prevstate.customers,{ ...action.customer }]};
        case CustomerActionTypes.CUSTOMER_UPDATE_SUCCESS:
            return {...prevstate, customers: prevstate.customers.map(customer =>
                customer.id === action.customer.id ? action.customer : customer
            )};  
        case CustomerActionTypes.CUSTOMER_DELETE_SUCCESS:
            return {...prevstate, customers: prevstate.customers.filter(customer =>
                action.customerids.indexOf(customer.id) === -1
            )};                                        
        default:
            return prevstate;
    }
}
