// action types
export enum SharedActionTypes {
    API_CALL_BEGIN = "API_CALL_BEGIN",
    API_CALL_ERROR = "API_CALL_ERROR",
    ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR",
    CLOSE_SNACKBAR = 'CLOSE_SNACKBAR',
    REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'
}

export interface IApiCallBeginAction {
    type: SharedActionTypes.API_CALL_BEGIN; 
}

export interface IIApiCallErrorAction {
    type: SharedActionTypes.API_CALL_ERROR;
    error: string;
}

export interface IEnqueueSnackbarAction {
    type: SharedActionTypes.ENQUEUE_SNACKBAR; 
    notification: any;
}

export interface IRemoveSnackbarAction {
    type: SharedActionTypes.REMOVE_SNACKBAR; 
    key:any;
}

export interface ICloseSnackbarAction {
    type: SharedActionTypes.CLOSE_SNACKBAR; 
    dismissAll:boolean;
    key:any;

}


export type TApiCallAction = IApiCallBeginAction | IIApiCallErrorAction | IEnqueueSnackbarAction | IRemoveSnackbarAction | ICloseSnackbarAction;

export const actionCreators = {
    beginApiCall: ():IApiCallBeginAction => {
        return { type: SharedActionTypes.API_CALL_BEGIN };
    },
    apiCallError: (error: string):IIApiCallErrorAction => {
        return { 
            type: SharedActionTypes.API_CALL_ERROR,
            error
        };
    },
    enqueueSnackbar: (notification:any):IEnqueueSnackbarAction => {
        const key = notification.options && notification.options.key;
        return {
            type: SharedActionTypes.ENQUEUE_SNACKBAR,
            notification: {
                ...notification,
                key: key || new Date().getTime() + Math.random(),
            }
        }
    },
    closeSnackbar: (key:any):ICloseSnackbarAction => {
        return {
            type: SharedActionTypes.CLOSE_SNACKBAR,
            dismissAll: !key, // dismiss all if no key has been defined
            key
        }
    },
    removeSnackbar: (key:any):IRemoveSnackbarAction => {
        return {
            type: SharedActionTypes.REMOVE_SNACKBAR,
            key
        }
    }
}