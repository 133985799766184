import TagManager from 'react-gtm-module';

const { REACT_APP_GA_MEASUREMENT_ID: GA_MEASUREMENT_ID } = process.env;

const mainTagManagerArgs = {
    gtmId: GA_MEASUREMENT_ID || 'GTM-NS9FDQG',
    
}

const init = ( userId: string | null) => {
    if(userId !== null){
        const args = {...mainTagManagerArgs, ...{dataLayer: {userId }}};

        TagManager.initialize(args);
    }else{
        TagManager.initialize(mainTagManagerArgs);
    }
};

const sendEvent = (payload:any) => {
    
};

const sendPageview = (path:string, title:string, lang:string) => {
    const ev = {
        event: "pageview",
        page: {
            path,
            title,
            lang
        }
    };

    if (process.env.NODE_ENV !== 'production') {
        console.log(ev);
    }else{
        if((window as any).dataLayer){
            (window as any).dataLayer.push(ev);
        }
    }
};

export default {
    init,
    sendEvent,
    sendPageview
};
