import moment from "moment";
import { DateRangePickerViews } from "../../components/AppRangePicker";
import { TimeEntry, TimeEntrySummary, TimeEntryStatus } from "../models/TimeEntry";

export const TimeEntriesFilterValues = {
    status: [TimeEntryStatus.NEW, TimeEntryStatus.SUBMITTED, TimeEntryStatus.APPROVED, TimeEntryStatus.REJECTED,TimeEntryStatus.PROFORMA].sort((a, b) => b.localeCompare(a)),
    companyId: '',
    companyIds: [],
    projectId: '',
    employeeId: '',
    customerId: '',
    contactId: ''
}

export interface ITimeEntriesState {
    total:number;
    timeentries: Array<TimeEntry>;
    summary: TimeEntrySummary;
    filter: any;
    daterange: {
        view:DateRangePickerViews;
        start?:Date;
        end?:Date;
    };
}

export const initialTimeEntriesState:ITimeEntriesState  = {
    total:0,
    timeentries:[],
    summary: {
        total: 0,
        items: []
    },
    filter: TimeEntriesFilterValues,
    daterange:{
        view:DateRangePickerViews.WEEK,
        start: moment().utc(true).startOf('week').toDate(),
        end: moment().utc(true).endOf('week').toDate()
    }
}