import { ITimeEntriesState, initialTimeEntriesState } from "./state";
import { TimeEntryActionTypes, TTimeEntryAction } from "./actions";
import { TimeEntrySummaryItem } from "../models/TimeEntry";
import { Reducer } from "redux";

export const reducer:Reducer<ITimeEntriesState> = (prevstate:ITimeEntriesState = initialTimeEntriesState, action:TTimeEntryAction) : ITimeEntriesState => {
    switch(action.type){
        case TimeEntryActionTypes.TIMEENTRIES_LOAD_SUCCESS:
            return {...prevstate,  total: action.total, timeentries: action.timeentries, filter: action.filter || prevstate.filter, daterange: action.daterange || prevstate.daterange};
        case TimeEntryActionTypes.TIMEENTRY_CREATE_SUCCESS:
            return {...prevstate, timeentries: [...prevstate.timeentries, { ...action.timeentry }]};
        case TimeEntryActionTypes.TIMEENTRY_UPDATE_SUCCESS:
            return {...prevstate, timeentries: prevstate.timeentries.map(timeentry =>
                timeentry.id === action.timeentry.id ? action.timeentry : timeentry
            )};  
        case TimeEntryActionTypes.TIMEENTRY_DELETE_SUCCESS:
            return {...prevstate, timeentries: prevstate.timeentries.filter(timeentry =>
                action.timeentryids.indexOf(timeentry.id) === -1
            )};
        case TimeEntryActionTypes.TIMEENTRY_COPY_SUCCESS:
            return {...prevstate, timeentries: [...action.timeentries, ...prevstate.timeentries]};                
        case TimeEntryActionTypes.TIMEENTRIES_SUMMARY_LOAD_SUCCESS:
            return {...prevstate, summary: {
                    total: action.items ? action.items.reduce((total:number, item:TimeEntrySummaryItem) => total + item.Value,0) : 0,
                    items: action.items
                }
            };
        case TimeEntryActionTypes.TIMEENTRIES_UPDATE_STATUS_SUCCESS:
            return {...prevstate, timeentries: prevstate.timeentries.map(timeentry => {
                let t = action.updateStatusResult.find(x => x.timeEntryId === timeentry.id);
                    return t === undefined ? timeentry : {...timeentry, status: t.newStatus, invoiceRef: t.invoiceRef !== undefined ? t.invoiceRef : timeentry.invoiceRef}
                }
            )};                                                      
        default:
            return prevstate;
    }
}
