import { NormalizedRole } from "../models/Role";
import { User } from "oidc-client";

export enum UserSettingKey {
  LANGUAGE = "LANGUAGE"
};

export type UserSetting = {
  key:UserSettingKey;
  value:string;
};

export interface IUserState {
  loading: boolean;
  authenticated: boolean;
  name: string;
  username: string;
  role:NormalizedRole;
  accountId:string;
  accountName:string;
  authUser: User | null;
  userSettings: UserSetting[];
}

export const initialUserState: IUserState = {
    loading: true,
    authenticated: false,
    name: "",
    username:"",
    role: NormalizedRole.UNKNOWN,
    accountId: "",
    accountName: "",
    authUser: null,
    userSettings: []
}