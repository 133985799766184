import { Reducer } from "redux";
import { ICountriesState, initialCountriesState } from "./state";
import { CountryActionTypes, TCountryAction } from "./actions";

export const reducer:Reducer<ICountriesState> = (prevstate:ICountriesState = initialCountriesState, action:TCountryAction) : ICountriesState => {
    switch(action.type){
        case CountryActionTypes.COUNTRIES_LOAD_SUCCESS:
            return {...prevstate, countries: action.countries};
        default:
            return prevstate;
    }
}
