import { Reducer } from "redux";
import { IApiState, initialApiState} from "./state";
import { SharedActionTypes, TApiCallAction } from "./actions";

function actionTypeEndsInSuccess(type:string) {
  return type.substring(type.length - 8) === "_SUCCESS";
}

export const reducer: Reducer<IApiState> = (prevstate = initialApiState, action:TApiCallAction): IApiState => {
  switch (action.type){
    case SharedActionTypes.API_CALL_BEGIN:
      return {
        ...prevstate,
        apiCallsInProgress: prevstate.apiCallsInProgress + 1
      };
    case SharedActionTypes.API_CALL_ERROR || actionTypeEndsInSuccess(action.type):
      return {
        ...prevstate,
        apiCallsInProgress: prevstate.apiCallsInProgress - 1
      }; 
    case SharedActionTypes.ENQUEUE_SNACKBAR:
      return {
        ...prevstate,
        notifications: [
          ...prevstate.notifications,
          {
              ...action.notification,
          },
      ]}
    case SharedActionTypes.CLOSE_SNACKBAR:
      return{
        ...prevstate,
        notifications: prevstate.notifications.map(notification => (
            (action.dismissAll || notification.key === action.key)
                ? { ...notification, dismissed: true }
                : { ...notification }
        ))
      }
    case SharedActionTypes.REMOVE_SNACKBAR:
      return{
        ...prevstate,
        notifications: prevstate.notifications.filter(
            notification => notification.key !== action.key,
        )
      }   

    default:
        return prevstate; 
  }
};
