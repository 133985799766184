import { Reducer } from "redux";
import { IUserState, initialUserState } from "./state";
import { UserActionTypes, TUserAction } from "./actions";

export const reducer:Reducer<IUserState> = (prevstate:IUserState = initialUserState, action:TUserAction) : IUserState => {
    switch(action.type){
  
        case UserActionTypes.LOGIN_SUCCESS:
            return {...prevstate,  authUser: action.authUser };
        case UserActionTypes.RENEW_SUCCESS:
            return {...prevstate,  authUser: action.authUser};            
        case UserActionTypes.LOGOUT:
            return { ...prevstate, ...initialUserState};
        case UserActionTypes.USER_LOAD_SUCCESS:
            return { ...prevstate, loading:false, username: action.username, name: action.name, role: action.role, 
                accountId: action.accountId, accountName: action.accountName};
        case UserActionTypes.LOAD_SETTINGS_SUCCESS:
            return { ...prevstate, userSettings: action.userSettings };
        default:
            return prevstate;
    }
}
