import { useEffect } from 'react';
import { useHistory } from 'react-router';

function ScrollReset() {
  const history = useHistory();

  // useEffect(() => {
  //   window.addEventListener('popstate', handleBack)
  //   return () => window.removeEventListener('popstate', handleBack)
  // }, [])

  // function handleBack () {
  //   const state = { ...history.location.state };
  //   state.restore = true;
  //   history.replace({ ...history.location, state });
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  return null;
}

export default ScrollReset;
