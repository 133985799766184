import { Reducer } from "redux";
import { IContactsState, initialContactsState } from "./state";
import { ContactActionTypes, TContactAction } from "./actions";

export const reducer:Reducer<IContactsState> = (prevstate:IContactsState = initialContactsState, action:TContactAction) : IContactsState => {
    switch(action.type){
        case ContactActionTypes.CONTACTS_LOAD_SUCCESS:
            return {...prevstate, total: action.total, contacts: action.contacts};
        case ContactActionTypes.CONTACT_CREATE_SUCCESS:
            return {...prevstate, contacts: [...prevstate.contacts,{ ...action.contact }]};
        case ContactActionTypes.CONTACT_UPDATE_SUCCESS:
            return {...prevstate, contacts: prevstate.contacts.map(contact =>
                contact.id === action.contact.id ? action.contact : contact
            )};  
        case ContactActionTypes.CONTACT_DELETE_SUCCESS:
            return {...prevstate, contacts: prevstate.contacts.filter(contact =>
                action.contactids.indexOf(contact.id) === -1
            )};                                        
        default:
            return prevstate;
    }
}
