import moment from 'moment';
import i18n from "../../i18n";
import { colors} from "@material-ui/core";
import { ExpenseRow } from "./Expense";
import { ImageRow } from "./Image";
import { EmployeeLookupItem } from './Employee';
import { ProjectLookupItem } from './Project';

export enum TimeEntryStatus {
    NEW =  "NEW" ,
    SUBMITTED = "SUBMITTED",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    PROFORMA = "PROFORMA",
    INVOICED = "INVOICED",
    ARCHIVED = "ARCHIVED"
}

export type TimeEntry = {
    id: string;
    projectId:string;
    projectName:string;
    companyId:string;
    companyName:string;     
    customerId:string;
    customerName:string;
    contactId:string;
    contactName:string;       
    employeeId:string;
    employeeName:string;   
    date:Date;
    minutes: number;
    location: string;
    descr: string;
    status: TimeEntryStatus;
    invoiceRef: string;
    expenses: ExpenseRow[];
    images: ImageRow[];
    ownProject:boolean;
}

export type EditTimeEntry = {
    timeEntry?: TimeEntry;
    projects: ProjectLookupItem[];
    employees: EmployeeLookupItem[];
}

export type TimeEntrySummary = {
    total: number,
    items: TimeEntrySummaryItem[]
}
export type TimeEntrySummaryItem = {
    Key: TimeEntryStatus,
    Value: number
}

export function newTimeEntry():TimeEntry {
    return {
    id: "",
    projectId:"",
    projectName:"",
    companyId:"",
    companyName:"",
    customerId:"",
    customerName:"",
    contactId:"",
    contactName:"",    
    employeeId:"",
    employeeName:"",
    date:  moment().utc(true).startOf('day').toDate(),
    minutes: 0,
    location: "",
    descr: "",
    status: TimeEntryStatus.NEW,
    invoiceRef: "",
    expenses: [],
    images: [],
    ownProject: false
}}

export function getTimeEntryById(timeentries: TimeEntry[], id: string):TimeEntry {
    if (timeentries.length > 0) {
        return timeentries.find(timeentries => timeentries.id === id) || newTimeEntry();
    }
    return newTimeEntry();
}

export type WeekEntries = {
    week:string,
    total: number,
    items: TimeEntry[]

}

export function datesGroupByWeek(entries:TimeEntry[]):any[] {
    let byWeek = entries.reduce((res:any, e:TimeEntry) => {
        let comp = moment(e.date).year()+'-'+ ("0" + moment(e.date).isoWeek()).slice(-2);
        res[comp] ? res[comp].minutes += e.minutes : res[comp] = {week: comp, minutes: e.minutes, items: [] };
        res[comp].items.push(e);
        return res;
      },{});

      let result = Object.values(byWeek).reduce((acc:any[], val:any) => {
          return acc.concat([{week: val.week, minutes: val.minutes, group:true }, ...val.items])
        },new Array<any>());

      return result;
}

export function translateStatus(status:TimeEntryStatus){
    switch(status)
    {
        case TimeEntryStatus.NEW : return i18n.t("timeentries:status.new");
        case TimeEntryStatus.SUBMITTED : return i18n.t("timeentries:status.submitted");
        case TimeEntryStatus.APPROVED : return i18n.t("timeentries:status.approved");
        case TimeEntryStatus.REJECTED : return i18n.t("timeentries:status.rejected");
        case TimeEntryStatus.INVOICED : return i18n.t("timeentries:status.invoiced");
        case TimeEntryStatus.ARCHIVED : return i18n.t("timeentries:status.archived");
        case TimeEntryStatus.PROFORMA : return i18n.t("timeentries:status.proforma");
        default : return "";
    }
}

export function statusToColor(status:TimeEntryStatus){
    switch(status)
    {
        case TimeEntryStatus.NEW : return colors.grey[500];
        case TimeEntryStatus.SUBMITTED : return colors.indigo[700];
        case TimeEntryStatus.APPROVED : return colors.orange[700];
        case TimeEntryStatus.REJECTED : return colors.red[700];
        case TimeEntryStatus.INVOICED : return colors.green[700];
        case TimeEntryStatus.ARCHIVED : return colors.grey[700];
        case TimeEntryStatus.PROFORMA : return colors.green[500];    
        default : return colors.common.black;
    }    
}

export type TimeEntryStatusLookupItem = {
    key:TimeEntryStatus;
    label: string;
}

export function TimeEntryStatusLookup():TimeEntryStatusLookupItem[] {
    return Object.values(TimeEntryStatus).map(x => { 
        return { key: x, label: translateStatus(x as TimeEntryStatus)} 
    });
}
