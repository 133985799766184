import { ThunkAction } from 'redux-thunk'
import * as companiesService from "../../services/companiesService";
import { Company } from "../models/Company";
import { actionCreators as sharedActions, TApiCallAction } from "../shared/actions";
import { ApplicationState } from "../"

// action types
export enum CompanyActionTypes {
    COMPANIES_LOAD_SUCCESS = "COMPANIES_LOAD_SUCCESS",
    COMPANY_CREATE_SUCCESS = "COMPANY_CREATE_SUCCESS",
    COMPANY_UPDATE_SUCCESS = "COMPANY_UPDATE_SUCCESS",
    COMPANY_DELETE_SUCCESS = "COMPANIES_DELETE_SUCCESS"
}
  
export interface ICompanyAction {
    type: CompanyActionTypes;
  }

export interface ICompanyLoadSuccess extends ICompanyAction {
    type: CompanyActionTypes.COMPANIES_LOAD_SUCCESS;
    total:number;
    companies:Array<Company>;
}

export interface ICreateCompaniesuccessAction {
    type: CompanyActionTypes.COMPANY_CREATE_SUCCESS;
    company: Company;
}
  
export interface IUpdateCompaniesuccessAction {
    type: CompanyActionTypes.COMPANY_UPDATE_SUCCESS;
    company: Company;
}

export interface IDeleteCompaniesuccessAction {
    type: CompanyActionTypes.COMPANY_DELETE_SUCCESS;
    companyids: string[];
}
function loadSuccess(total:number, companies:Array<Company>): ICompanyLoadSuccess {
    return {
        type: CompanyActionTypes.COMPANIES_LOAD_SUCCESS,
        total,
        companies
    }
}

function createSuccess(company:Company): ICreateCompaniesuccessAction {
    return {
        type: CompanyActionTypes.COMPANY_CREATE_SUCCESS,
        company
    }
}

function updateSuccess(company:Company): IUpdateCompaniesuccessAction {
    return {
        type: CompanyActionTypes.COMPANY_UPDATE_SUCCESS,
        company
    }
}

function deleteSuccess(companyids:string[]): IDeleteCompaniesuccessAction {
    return {
        type: CompanyActionTypes.COMPANY_DELETE_SUCCESS,
        companyids
    }
}

export type TCompanyAction = ICompanyLoadSuccess | ICreateCompaniesuccessAction | IUpdateCompaniesuccessAction | IDeleteCompaniesuccessAction | TApiCallAction;

export const actionCreators = {
    loadCompany: (id:string): ThunkAction<Promise<void>, ApplicationState, {}, TCompanyAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(0,[]));

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await companiesService.getById(accountId,id)
        .then(data => {
            dispatch(loadSuccess(1, [data]));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },
    load: (filter?:string, orderby?:string): ThunkAction<Promise<void>, ApplicationState, {}, TCompanyAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(0,[]));

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await companiesService.getAll(accountId,filter,orderby)
        .then(data => {
            dispatch(loadSuccess(data.total, data.items));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },
    save: (company:Company) : ThunkAction<Promise<string>, ApplicationState, {}, TCompanyAction> => async (dispatch, getState):Promise<string> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        if(company.id){
            return await companiesService.update(accountId, company)
            .then(data => {
                dispatch(updateSuccess(data));
                return data.id;
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
                });
        }else{
            return await companiesService.create(accountId, company)
            .then(data => {
                dispatch(createSuccess(data));
                return data.id;
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
                });
        }
    },
    delete: (companyids:string[]) : ThunkAction<Promise<void>, ApplicationState, {}, TCompanyAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        
        return await companiesService.deleteMultiple(accountId, companyids)
        .then(data => {
            dispatch(deleteSuccess(data));
        }).catch(error => {
        dispatch(sharedActions.apiCallError(error));
            throw error;
        });

    }    
}