import { Country } from "../models/Country";
import { actionCreators as sharedActions, TApiCallAction } from "../shared/actions";
import { ThunkAction } from 'redux-thunk'
import * as countriesService from "../../services/countriesService";
import { ApplicationState } from "../"

// action types
export enum CountryActionTypes {
    COUNTRIES_LOAD_SUCCESS = "COUNTRIES_LOAD_SUCCESS"
}
  

export interface ICountryAction {
    type: CountryActionTypes;
  }

// load
export interface ICountriesLoadSuccess extends ICountryAction {
    type: CountryActionTypes.COUNTRIES_LOAD_SUCCESS;
    countries:Array<Country>;
}

export function loadSuccess(countries:Array<Country>): ICountriesLoadSuccess{
    return {
        type: CountryActionTypes.COUNTRIES_LOAD_SUCCESS,
        countries
    }
}

export type TCountryAction = ICountriesLoadSuccess | TApiCallAction;

export const actionCreators = {
    load: (): ThunkAction<Promise<void>, ApplicationState, {}, TCountryAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await countriesService.getAll(accountId)
        .then(data => {
            dispatch(loadSuccess(data));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },
}