import { handleResponse, handleError, defaultSecureHeaders, getFilterAndSort } from "./apiUtils";
import { Company, CompanyEmployee } from "../store/models/Company";
import { store } from "../App";
import { PagedResults } from "../store/models/PagedResults";

export async function getAll(accountid:string, filter?:string, orderby?:string):Promise<PagedResults<Company>> {
  return getByUrl("/companies/", accountid, filter, orderby);
}

export async function getCompanyEmployees(accountid:string, companyid:string, filter?:string, orderby?:string):Promise<PagedResults<CompanyEmployee>> {
  return getByUrl("/companies/" + companyid + "/employees/", accountid, filter, orderby);
}

export async function getById(accountid:string, id:string):Promise<Company> {
  return getByUrl("/companies/" + id, accountid);
}

async function getByUrl(url:string, accountid:string, filter?:string, orderby?:string):Promise<any> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + url + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function create(accountid:string, company:Company):Promise<Company> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/companies", {
    method: "POST", 
    headers: await defaultSecureHeaders(),
    body: JSON.stringify(company)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function update(accountid:string, company:Company):Promise<Company> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/companies/" + company.id, {
    method: "POST", 
    headers: await defaultSecureHeaders(),
    body: JSON.stringify(company)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteMultiple(accountid:string, companyids:string[]):Promise<string[]> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/companies", { 
    method: "DELETE",
    headers: await defaultSecureHeaders(),
    body: JSON.stringify({
      companyids
    })
  })
  .then(handleResponse)
  .catch(handleError);
}

export async function addCompanyEmployees(accountid:string, companyid:string, employeeids:string[]):Promise<any> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/companies/" + companyid + "/employees", {
    method: "POST", 
    headers: await defaultSecureHeaders(),
    body: JSON.stringify({
      employeeids
    })
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteCompanyEmployees(accountid:string, companyid:string, employeeids:string[]):Promise<any> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/companies/" + companyid + "/employees", {
    method: "DELETE", 
    headers: await defaultSecureHeaders(),
    body: JSON.stringify({
      employeeids
    })
  })
    .then(handleResponse)
    .catch(handleError);
}