import { UserManager } from "oidc-client";

export interface IAppSettings {
    stsAuthority: string;
    clientId: string;
    clientRoot: string;
    clientScope: string;
    apiBaseUrl: string;
    loading: boolean;
    userManager: UserManager | null;
} 
  
export const initialAppSettings: IAppSettings = {
    stsAuthority: "",
    clientId: "",
    clientRoot: "",
    clientScope: "",
    apiBaseUrl: "",
    loading: true,
    userManager: null
}