import React, {  useEffect } from "react";
import { LinearProgress, makeStyles } from "@material-ui/core";
import Page from "./Page";
import { signinRedirectCallback } from "../services/authService";

const useStyles = makeStyles(()=> ({
    root: {
        paddingTop: 0,
        paddingBottom: 0
    }
}));

const OIDCCallback: React.FC<{}> = props => {
    const classes = useStyles(props);
    useEffect(() => {
        signinRedirectCallback();
    },[]);

    return (
        <Page className={classes.root} title="Authentification callback processing..">
            <LinearProgress />
        </Page>
    )
};

export default OIDCCallback;


