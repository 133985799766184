import { Reducer } from "redux";
import { ICompaniesState, initialCompaniesState } from "./state";
import { CompanyActionTypes, TCompanyAction } from "./actions";
import { CompanyEmployeeActionTypes, TCompanyEmployeeAction } from "./companyEmployee/actions";
import { companyEmployeeReducer }  from "./companyEmployee/reducers";

export const reducer:Reducer<ICompaniesState> = (prevstate:ICompaniesState = initialCompaniesState, action:TCompanyAction | TCompanyEmployeeAction) : ICompaniesState => {
    if(Object.values(CompanyEmployeeActionTypes).includes(action.type as CompanyEmployeeActionTypes)){
        return companyEmployeeReducer(prevstate, action as TCompanyEmployeeAction);
    }else{
        switch(action.type){
            case CompanyActionTypes.COMPANIES_LOAD_SUCCESS:
                return {...prevstate, total: action.total, companies: action.companies};
            case CompanyActionTypes.COMPANY_CREATE_SUCCESS:
                return {...prevstate, companies: [...prevstate.companies,{ ...action.company }]};
            case CompanyActionTypes.COMPANY_UPDATE_SUCCESS:
                return {...prevstate, companies: prevstate.companies.map(company =>
                    company.id === action.company.id ? action.company : company
                )};  
            case CompanyActionTypes.COMPANY_DELETE_SUCCESS:
                return {...prevstate, companies: prevstate.companies.filter(company =>
                    action.companyids.indexOf(company.id) === -1
                )};                                        
            default:
                return prevstate;
        }
    }
}
