import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import UnsecureLayout from "./layouts/Unsecure";
import SecureLayout from "./layouts/Secure";
import AppRoutes from "./routeConstants";
import { NormalizedRole } from "./store/models/Role";
import OIDCCallback from "./components/OIDCCallback";

export default [
    // {
    //     path: AppRoutes.ROOT,
    //     exact: true,
    //     component: () => <Redirect to={AppRoutes.DASHBOARD} />
    // },
    {
        path: AppRoutes.OIDC_CALLBACK,
        exact: true,
        component: () => <OIDCCallback />
    },
    {
        path: AppRoutes.ERRORS,
        component: UnsecureLayout,
        routes: [
            {
                name: "Error/401",
                path: AppRoutes.ERRORS_401,
                exact: true,
                component: lazy(() => import('./views/Errors/Error401'))
            },
            {
                name: "Error/403",
                path: AppRoutes.ERRORS_403,
                exact: true,
                component: lazy(() => import('./views/Errors/Error403'))
            },
            {
                name: "Error/404",
                path: AppRoutes.ERRORS_404,
                exact: true,
                component: lazy(() => import('./views/Errors/Error404'))
            },
            {
                component: () => <Redirect to={AppRoutes.ERRORS_404} />
            }
        ]
    },
    {
        path: AppRoutes.ACCOUNT,
        component: UnsecureLayout,
        routes: [
            {
                name: "Account/Register",
                path: AppRoutes.ACCOUNT_REGISTER,
                exact: true,
                component: lazy(() => import('./views/Account/Register'))
            },
            {
                name: "Account/Confirm",
                path: AppRoutes.ACCOUNT_CONFIRM,
                exact: true,
                component: lazy(() => import('./views/Account/Confirm'))
            },            
            {
                component: () => <Redirect to={AppRoutes.ERRORS_404} />
            }
        ]
    },
    {
        route: "*",
        component: SecureLayout,
        routes: [
            {
                name: "Dashboard",
                path: AppRoutes.ROOT,
                exact: true,
                component: lazy(() => import('./views/Dashboard'))
            },
            {
                name: "Dashboard",
                path: AppRoutes.DASHBOARD,
                exact: true,
                component: lazy(() => import('./views/Dashboard'))
            },
            {
                name: "Profile",
                path: AppRoutes.PROFILE,
                exact: true,
                component: lazy(() => import("./views/Profile/")),
            },
            {
                name: "Profile",
                path: AppRoutes.PROFILE_TAB,
                component: lazy(() => import("./views/Profile/"))
            },
            {
                name: "Hours",
                path: AppRoutes.TIMEENTRIES,
                exact: true,
                component: lazy(() => import("./views/TimeEntriesList/")),
            },
            {
                name: "Hours",
                path: AppRoutes.TIMEENTRIES_TAB,
                component: lazy(() => import("./views/TimeEntriesList/"))
            },
            {
                name: "Project",
                path: AppRoutes.PROJECTS_ADD,
                exact: true,
                component: lazy(() => import("./views/ProjectDetails")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Project",
                path: AppRoutes.PROJECTS_DETAILS,
                exact: true,
                component: lazy(() => import("./views/ProjectDetails")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Project",
                path: AppRoutes.PROJECTS_DETAILS_TAB,
                component: lazy(() => import("./views/ProjectDetails")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Projects",
                path: AppRoutes.PROJECTS,
                exact: true,
                component: lazy(() => import("./views/ProjectsList")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Company",
                path: AppRoutes.COMPANIES_ADD,
                exact: true,
                component: lazy(() => import("./views/CompanyDetails")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Company",
                path: AppRoutes.COMPANIES_DETAILS,
                exact: true,
                component: lazy(() => import("./views/CompanyDetails")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Company",
                path: AppRoutes.COMPANIES_DETAILS_TAB,
                component: lazy(() => import("./views/CompanyDetails")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Companies",
                path: AppRoutes.COMPANIES,
                exact: true,
                component: lazy(() => import("./views/CompaniesList")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Employee",
                path: AppRoutes.EMPLOYEES_ADD,
                exact: true,
                component: lazy(() => import("./views/EmployeeDetails")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Employee",
                path: AppRoutes.EMPLOYEES_DETAILS,
                exact: true,
                component: lazy(() => import("./views/EmployeeDetails")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Employee",
                path: AppRoutes.EMPLOYEES_DETAILS_TAB,
                component: lazy(() => import("./views/EmployeeDetails")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Employees",
                path: AppRoutes.EMPLOYEES,
                exact: true,
                component: lazy(() => import("./views/EmployeesList")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Customer",
                path: AppRoutes.CUSTOMERS_ADD,
                exact: true,
                component: lazy(() => import("./views/CustomerDetails")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Customer",
                path: AppRoutes.CUSTOMERS_DETAILS,
                exact: true,
                component: lazy(() => import("./views/CustomerDetails")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Customer",
                path: AppRoutes.CUSTOMERS_DETAILS_TAB,
                component: lazy(() => import("./views/CustomerDetails")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Customers",
                path: AppRoutes.CUSTOMERS,
                exact: true,
                component: lazy(() => import("./views/CustomersList")),
                roles: [NormalizedRole.ACCOUNT_OWNER]
            },
            {
                name: "Reports/ProjectTotals",
                path: AppRoutes.REPORTS_PROJECTTOTALS,
                exact: true,
                component: lazy(() => import("./views/Reports/ProjectTotals")),
                roles: [NormalizedRole.ACCOUNT_OWNER, NormalizedRole.PROJECT_MANAGER]
            },
            {
                name: "Reports/EmployeeTotals",
                path: AppRoutes.REPORTS_EMPLOYEETOTALS,
                exact: true,
                component: lazy(() => import("./views/Reports/EmployeeTotals")),
                roles: [NormalizedRole.ACCOUNT_OWNER, NormalizedRole.PROJECT_MANAGER]
            },
            {
                component: () => <Redirect to={AppRoutes.ERRORS_404} />
            }
        ]
    }
]