import { ThunkAction } from "redux-thunk";
import { getAppSettings } from "../../services/apiUtils";
import { actionCreators as sharedActions, TApiCallAction } from "../shared/actions";
import { IAppSettings } from "./state";
import { UserManager } from "oidc-client";

export enum AppActionTypes {
    APPSETTINGS_LOAD_SUCCESS = "APPSETTINGS_LOAD_SUCCESS",

}

export interface IAppSettingsLoadSuccessAction {
    type: AppActionTypes.APPSETTINGS_LOAD_SUCCESS;
    appSettings: IAppSettings;
}
  
function loadAppSettingsSuccess(appSettings:IAppSettings): IAppSettingsLoadSuccessAction{
    
    const authSettings = {
        authority: appSettings.stsAuthority,
        client_id: appSettings.clientId,
        redirect_uri: `${appSettings.clientRoot}signin-callback`,
        silent_redirect_uri: `${appSettings.clientRoot}silent-renew.html`,
        post_logout_redirect_uri: `${appSettings.clientRoot}`,
        response_type: 'code',
        response_mode: 'query',
        scope: appSettings.clientScope,
        //automaticSilentRenew: true
      };
      appSettings.userManager = new UserManager(authSettings);

    return {
        type: AppActionTypes.APPSETTINGS_LOAD_SUCCESS,
        appSettings
    }
}
export type TAppAction = IAppSettingsLoadSuccessAction | TApiCallAction;

export const actionCreators = {
    loadApp:():ThunkAction<Promise<void>, {}, {}, TAppAction> => async (dispatch):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        try{
            await getAppSettings()
            .then(data =>{
                dispatch(loadAppSettingsSuccess(data));
            });
        }catch(error){
            dispatch(sharedActions.apiCallError(error));
            throw error;
        }  
    },

}