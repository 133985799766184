import { IAppSettings } from "../store/app/state";
import { store } from "../App";
import { actionCreators as userActions } from "../store/user/actions";
import { history } from "../App";

export async function handleResponse(response: any) {
  if (response.ok) return response.json();
  if(response.status === 401){
    const error = await response.json();
    throw error;
  }
  if(response.status === 403){
    const error = await response.json();
    throw error;
  }
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.json();
    throw new Error(error.detail);
  }
  throw new Error("Network response was not ok.");
}

// In a real app, would likely call an error logging service.
export function handleError(error: any) {
  console.error("API call failed.", error);
  if(error.status === 403){
    history.replace(history.location.pathname, { 
        errorStatusCode: error.status 
    });
  }

  throw error;
}

export function defaultHeaders() {
  return {
    "Accept": "application/json",
    "Content-Type": "application/json",
  };
}

export async function defaultSecureHeaders() {
  const state = store.getState();
  let user = state.user.authUser;

  if(user?.expired === true){
    user = await userActions.renewUser();
  }

  return {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": user?.token_type + " " + user?.access_token
  };
}

export async function defaultSecureFormaDataHeaders() {
  const state = store.getState();
  let user = state.user.authUser;

  if(user?.expired === true){
    user = await userActions.renewUser();
  }

  return {
    "Accept": "application/json",
    "Authorization": user?.token_type + " " + user?.access_token
  };
}

export async function defaultAccessToken() {
  const state = store.getState();
  let user = state.user.authUser;

  if(user?.expired === true){
    user = await userActions.renewUser();
  }

  return "access_token=" + user?.access_token;
}

export function getAppSettings():Promise<IAppSettings> {
  return fetch(`/settings/environment.json?cb=${Date.now()}`)
    .then(handleResponse)
    .catch(handleError);
}

export function getFilterAndSort(filter?:string, orderby?:string):string {
  let q = [];
  if(filter)
  {
    q.push("$filter=" + filter);
  }
  if(orderby){
    q.push("$orderby=" + orderby);
  }
  let qs = q.join("&");
  if(qs.length > 0){
    qs = "?" + qs;
  }
  return qs;
}