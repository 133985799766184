export type Role = {
    id: string;
    name: string;
}

export enum NormalizedRole {
    UNKNOWN = "UNKNOWN",
    ACCOUNT_OWNER = "ACCOUNT_OWNER",
    TEAM_MEMBER = "TEAM_MEMBER",
    PROJECT_OWNER = "PROJECT_OWNER",
    PROJECT_MANAGER = "PROJECT_MANAGER"
}

export function translateRole(roleNormalized:NormalizedRole){
    switch(roleNormalized)
    {
        case NormalizedRole.ACCOUNT_OWNER : return "Account Owner";
        case NormalizedRole.TEAM_MEMBER : return "Team Member";
        case NormalizedRole.PROJECT_OWNER : return "Project Owner";
        case NormalizedRole.PROJECT_MANAGER : return "Project Manager";
        default : return "Unknown";
    }
}

export function RolesLookup(currentRole:NormalizedRole) {
    const exclude = [NormalizedRole.UNKNOWN];
    if(currentRole !== NormalizedRole.ACCOUNT_OWNER){
        exclude.push(NormalizedRole.ACCOUNT_OWNER);
    }

    return Object.values(NormalizedRole).filter(x => exclude.indexOf(x) < 0).map(x => { 
        return { key: x, label: translateRole(x as NormalizedRole)} 
    });
}