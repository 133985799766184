import { Project, ProjectType, ProjectStatus } from "../models/Project";

export enum ProjectsFilters {
    Projects = 0,
    CustomerProjects = 1,
    CompanyProjects = 2,
    EmployeeProjects = 3
}

export interface IFilter {
    key: ProjectsFilters;
}

export const ProjectsFilterValues = {
    key: ProjectsFilters.Projects,
    status: [ProjectStatus.NEW, ProjectStatus.OFFER, ProjectStatus.PROFORMA, ProjectStatus.IN_PROGRESS, ProjectStatus.ON_HOLD].sort((a, b) => b.localeCompare(a)),
    companyId: '',
    customerId: ''
}

export const CustomerProjectsFilterValues = {
    ...ProjectsFilterValues, key: ProjectsFilters.CustomerProjects
}

export const CompanyProjectsFilterValues = {
    ...ProjectsFilterValues, key: ProjectsFilters.CompanyProjects
}

export const EmployeeProjectsFilterValues = {
    ...ProjectsFilterValues, key: ProjectsFilters.EmployeeProjects
}

export interface IProjectsState {
    total:number;
    projects: Array<Project>;
    latest:  Array<Project>;
    projectTypes: Array<ProjectType>;
    filter: IFilter[];
}

export const initialProjectsState  = {
    total:0,
    projects:[],
    latest:[],
    projectTypes:[],
    filter: [
        ProjectsFilterValues,
        CustomerProjectsFilterValues,
        CompanyProjectsFilterValues, 
        EmployeeProjectsFilterValues
    ]
}