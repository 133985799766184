import { handleResponse, handleError, defaultSecureHeaders, getFilterAndSort, defaultAccessToken, defaultSecureFormaDataHeaders } from "./apiUtils";
import { PagedResults } from "../store/models/PagedResults";
import { TimeEntry, TimeEntrySummaryItem, EditTimeEntry, TimeEntryStatus } from "../store/models/TimeEntry";
import { LookupsModel } from "../store/models/Lookup";
import { store } from "../App";

export async function getLookupsForCurrentUser():Promise<LookupsModel> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + state.user.accountId + "/your/timeentries/lookups", {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getLookups():Promise<LookupsModel> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + state.user.accountId + "/timeentries/lookups", {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getSummary(accountid:string):Promise<Array<TimeEntrySummaryItem>> {
  const state = store.getState();
  return fetch(state.appSettings.apiBaseUrl + accountid + "/your/timeentries/summary", {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getAll(accountid:string,filter?:string, orderby?:string):Promise<PagedResults<TimeEntry>> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + "/timeentries" + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getForApproval(accountid:string,filter?:string, orderby?:string):Promise<PagedResults<TimeEntry>> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + "/timeentries/forapproval" + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getExcelByAccount(accountid:string,filter?:string, orderby?:string):Promise<void> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  const token =  (qs.length > 0 ? "&" : "?") + await defaultAccessToken();
  window.location.href = state.appSettings.apiBaseUrl + accountid + "/timeentries/excel" + qs + token;
}

export async function getByIdForCurrentUser(timeentryid?:string, companyid?:string, customerId?:string):Promise<EditTimeEntry> {
  const state = store.getState();
  const url = state.appSettings.apiBaseUrl +  state.user.accountId + "/your/timeentries/edit";
  return getByIdForEdit(url, timeentryid, companyid, customerId);
}

export async function getByIdForAccount(timeentryid?:string, companyid?:string, customerId?:string):Promise<EditTimeEntry> {
  const state = store.getState();
  const url = state.appSettings.apiBaseUrl +  state.user.accountId + "/timeentries/edit";
  return getByIdForEdit(url, timeentryid, companyid, customerId);
}

async function getByIdForEdit(url:string, timeentryid?:string, companyid?:string, customerId?:string):Promise<EditTimeEntry> {
  let id = "";
  let qs = "";
  
  if(timeentryid) id = "/" + timeentryid;
  if(companyid) qs = "companyid=" + companyid;
  if(customerId) qs = "customerId=" + customerId;
  if(qs !== "") qs = "?" + qs;
  
  return fetch(url +  id + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getByCurrentUser(accountid:string, filter?:string, orderby?:string):Promise<PagedResults<TimeEntry>> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + "/your/timeentries" + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getExcelCurrentUser(accountid:string,filter?:string, orderby?:string):Promise<void> {
   const state = store.getState();
   const qs = getFilterAndSort(filter,orderby);
   var token =  (qs.length > 0 ? "&" : "?") + await defaultAccessToken();
   window.location.href = state.appSettings.apiBaseUrl + accountid + "/your/timeentries/excel" + qs + token;
}

export async function getByProject(accountid:string, projectid:string, filter?:string, orderby?:string):Promise<PagedResults<TimeEntry>> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + "/projects/" + projectid +"/timeentries" + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getExcelByProject(accountid:string, projectid:string,filter?:string, orderby?:string):Promise<void> {
   const state = store.getState();
   const qs = getFilterAndSort(filter,orderby);
   const token =  (qs.length > 0 ? "&" : "?") + await defaultAccessToken();
   window.location.href = state.appSettings.apiBaseUrl + accountid + "/projects/" + projectid + "/timeentries/excel" + qs + token;
}

export async function getByEmployee(accountid:string, employeeid:string, filter?:string, orderby?:string):Promise<PagedResults<TimeEntry>> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + "/employees/" + employeeid +"/timeentries" + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getExcelByEmployee(accountid:string, employeeid:string,filter?:string, orderby?:string):Promise<void> {
   const state = store.getState();
   const qs = getFilterAndSort(filter,orderby);
   const token =  (qs.length > 0 ? "&" : "?") + await defaultAccessToken();
   window.location.href = state.appSettings.apiBaseUrl + accountid + "/employees/" + employeeid + "/timeentries/excel" + qs + token;
}

export async function getByCompany(accountid:string, companyid:string,filter?:string, orderby?:string):Promise<PagedResults<TimeEntry>> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + "/companies/" + companyid +"/timeentries" + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getExcelByCompany(accountid:string, companyid:string,filter?:string, orderby?:string):Promise<void> {
   const state = store.getState();
   const qs = getFilterAndSort(filter,orderby);
   const token =  (qs.length > 0 ? "&" : "?") + await defaultAccessToken();
   window.location.href = state.appSettings.apiBaseUrl + accountid + "/companies/" + companyid + "/timeentries/excel" + qs + token;
}


export async function getByCustomer(accountid:string, customerid:string,filter?:string, orderby?:string):Promise<PagedResults<TimeEntry>> {
  const state = store.getState();
  const qs = getFilterAndSort(filter,orderby);
  return fetch(state.appSettings.apiBaseUrl + accountid + "/customers/" + customerid +"/timeentries" + qs, {
    method: "GET", 
    headers: await defaultSecureHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getExcelByCustomer(accountid:string, customerid:string,filter?:string, orderby?:string):Promise<void> {
   const state = store.getState();
   const qs = getFilterAndSort(filter,orderby);
   const token =  (qs.length > 0 ? "&" : "?") + await defaultAccessToken();
   window.location.href = state.appSettings.apiBaseUrl + accountid + "/customers/" + customerid + "/timeentries/excel" + qs + token;
}


export async function saveTimeEntry(timeentry:TimeEntry):Promise<TimeEntry> {
  const state = store.getState();

  if(timeentry.id !== ""){
    return update(state.user.accountId, timeentry.projectId, timeentry);
  }else{
    return create(state.user.accountId, timeentry.projectId, timeentry);
  }

}

export async function deleteTimeEntry(timeentryid:string):Promise<string[]> {
  const state = store.getState();
  return deleteMultiple(state.user.accountId, [timeentryid])
}

export async function create(accountid:string, projectid:string, timeentry:TimeEntry):Promise<TimeEntry> {
    const state = store.getState();

    let data:any = {
      projectId: timeentry.projectId,
      customerId: timeentry.customerId !== "" ? timeentry.customerId : null,
      employeeId: timeentry.employeeId !== "" ? timeentry.employeeId : null,
      contactId: timeentry.contactId !== "" ? timeentry.contactId : null,
      date: timeentry.date,
      minutes: timeentry.minutes,
      location: timeentry.location,
      descr: timeentry.descr,
      expenses: timeentry.expenses,
      status: timeentry.status,
      invoiceRef: timeentry.invoiceRef,
      images: []
    };

    // convert to formdata
    const formData = new FormData();
    formData.append("command", JSON.stringify(data));

    // add new files
    timeentry.images.forEach(image => {
      if(image.raw != null){
        formData.append("files", image.raw)
      }
    });

    return fetch(state.appSettings.apiBaseUrl + accountid + "/projects/" + projectid + "/timeentries", {
      method: "POST", 
      headers:  await defaultSecureFormaDataHeaders(),
      body: formData
    })
      .then(handleResponse)
      .catch(handleError);
  }
  
  export async function update(accountid:string, projectid:string, timeentry:TimeEntry):Promise<TimeEntry> {
    const state = store.getState();
    
    let data = {
      id: timeentry.id,
      projectId: timeentry.projectId,
      customerId: timeentry.customerId !== "" ? timeentry.customerId : null,
      employeeId: timeentry.employeeId !== "" ? timeentry.employeeId : null,
      contactId: timeentry.contactId !== "" ? timeentry.contactId : null,
      date: timeentry.date,
      minutes: timeentry.minutes,
      location: timeentry.location,
      descr: timeentry.descr,
      expenses: timeentry.expenses,
      status: timeentry.status,
      invoiceRef: timeentry.invoiceRef,
      images: timeentry.images.filter(x => x.id !== null).map(x => x.id)
    };

    // convert to formdata
    const formData = new FormData();
    formData.append("command", JSON.stringify(data));

    // add new files
    timeentry.images.forEach(image => {
      if(image.raw != null){
        formData.append("files", image.raw)
      }
    });
    
    return fetch(state.appSettings.apiBaseUrl + accountid + "/projects/" + projectid + "/timeentries/" + timeentry.id, {
      method: "POST", 
      headers:  await defaultSecureFormaDataHeaders(),
      body: formData
    })
      .then(handleResponse)
      .catch(handleError);
  }
  
  export async function deleteMultiple(accountid:string, timeentryids:string[]):Promise<string[]> {
    const state = store.getState();
    return fetch(state.appSettings.apiBaseUrl + accountid + "/timeentries", { 
      method: "DELETE",
      headers: await defaultSecureHeaders(),
      body: JSON.stringify({
        timeentryids
      })
    })
    .then(handleResponse)
    .catch(handleError);
  }

  export async function copyTimeEntry(timeentryid:string):Promise<TimeEntry> {
    const state = store.getState();
    var entries = await copyMultiple(state.user.accountId, [timeentryid]);
    return entries[0];
  }

  export async function copyMultiple(accountid:string, timeentryids:string[]):Promise<TimeEntry[]> {
    const state = store.getState();
    return fetch(state.appSettings.apiBaseUrl + accountid + "/timeentries/copy", { 
      method: "POST",
      headers: await defaultSecureHeaders(),
      body: JSON.stringify({
        timeentryids
      })
    })
    .then(handleResponse)
    .catch(handleError);
  }
  
  export type UpdateStatusResult = {
    timeEntryId: string;
    newStatus: TimeEntryStatus;
    invoiceRef: string;
  };

  export async function updateStatus(accountid:string, timeentryids:string[], newstatus:TimeEntryStatus, invoiceRef?:string):Promise<UpdateStatusResult[]> {
    const state = store.getState();
    return fetch(state.appSettings.apiBaseUrl + accountid + "/timeentries/status", { 
      method: "POST",
      headers: await defaultSecureHeaders(),
      body: JSON.stringify({
        timeentryids,
        newstatus,
        invoiceRef
      })
    })
    .then(handleResponse)
    .catch(handleError);
  }

  
export async function requestApproval(accountid:string,filter?:string):Promise<UpdateStatusResult[]> {
  const state = store.getState();
  const qs = getFilterAndSort(filter);
  return fetch(state.appSettings.apiBaseUrl + accountid + "/your/timeentries/requestapproval"  + qs, { 
    method: "POST",
    headers: await defaultSecureHeaders()
  })
  .then(handleResponse)
  .catch(handleError);
}
