import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AppRoutes from "../../routeConstants";
import { NormalizedRole } from '../../store/models/Role';

export interface NavConfigItem {
    subheader? :string,
    title: string,
    href: string,
    icon?: any,
    label?:string,
    depth?:number,
    roles?: string[],
    items?: NavConfigItem[],
    open?: boolean
}

const NavConfig:NavConfigItem[] = [{
    subheader: "Pages",
    title:"",
    href:"",
    items: [
        {
            title: "nav.dashboard",
            href: AppRoutes.DASHBOARD,
            icon: HomeIcon
        },
        {
            title: "nav.hours",
            href:  AppRoutes.TIMEENTRIES,
            icon: WatchLaterIcon,
            open: true,
            items:[
                {
                    title: "nav.hours.your",
                    href: AppRoutes.TIMEENTRIES_YOURS
                },
                {
                    title: "nav.hours.all",
                    href: AppRoutes.TIMEENTRIES_ALL,
                    roles: [NormalizedRole.ACCOUNT_OWNER, NormalizedRole.PROJECT_MANAGER]
                }   
            ]
        },   
        {
            title: "nav.approve",
            href: "#nav.approve",
            icon: AssignmentTurnedInIcon,
            roles: [NormalizedRole.ACCOUNT_OWNER, NormalizedRole.PROJECT_MANAGER],
            open: true,
            items:[
                {
                    title: "nav.approve.hours",
                    href: AppRoutes.TIMEENTRIES_APPROVE,
                    roles: [NormalizedRole.ACCOUNT_OWNER, NormalizedRole.PROJECT_MANAGER]
                } 
            ]
        },                        
        {
            title: "nav.projects",
            href: AppRoutes.PROJECTS,
            icon: BusinessCenterIcon,
            roles: [NormalizedRole.ACCOUNT_OWNER]
        },             
        {
            title: "nav.customers",
            href: AppRoutes.CUSTOMERS,
            icon: PermContactCalendarIcon,
            roles: [NormalizedRole.ACCOUNT_OWNER]
        },
        {
            title: "nav.organization",
            href: AppRoutes.ROOT,
            icon: BusinessIcon,
            open: true,
            roles: [NormalizedRole.ACCOUNT_OWNER],
            items:[
                {
                    title: "nav.organization.companies",
                    href: AppRoutes.COMPANIES,
                    roles: [NormalizedRole.ACCOUNT_OWNER]
                },
                {
                    title: "nav.organization.employees",
                    href: AppRoutes.EMPLOYEES,
                    roles: [NormalizedRole.ACCOUNT_OWNER]
                }   
            ]
        },
        {
            title: "nav.reports",
            href: "#nav.reports",
            icon: InsertChartIcon,
            open: true,
            roles: [NormalizedRole.ACCOUNT_OWNER, NormalizedRole.PROJECT_MANAGER],
            items:[
                {
                    title: "nav.reports.projecttotals",
                    href: AppRoutes.REPORTS_PROJECTTOTALS,
                    roles: [NormalizedRole.ACCOUNT_OWNER, NormalizedRole.PROJECT_MANAGER]
                },
                {
                    title: "nav.reports.employeetotals",
                    href: AppRoutes.REPORTS_EMPLOYEETOTALS,
                    roles: [NormalizedRole.ACCOUNT_OWNER, NormalizedRole.PROJECT_MANAGER]
                }
            ]
        }, 
    ]
}];

export default NavConfig