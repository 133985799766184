import { ThunkAction } from 'redux-thunk'
import * as customersService from "../../services/customersService";
import { Customer } from "../models/Customer";
import { actionCreators as sharedActions, TApiCallAction } from "../shared/actions";
import { ApplicationState } from "../"

// action types
export enum CustomerActionTypes {
    CUSTOMERS_LOAD_SUCCESS = "CUSTOMERS_LOAD_SUCCESS",
    CUSTOMER_CREATE_SUCCESS = "CUSTOMER_CREATE_SUCCESS",
    CUSTOMER_UPDATE_SUCCESS = "CUSTOMER_UPDATE_SUCCESS",
    CUSTOMER_DELETE_SUCCESS = "CUSTOMERS_DELETE_SUCCESS"
}
  
export interface ICustomerAction {
    type: CustomerActionTypes;
  }

export interface ICustomerLoadSuccess extends ICustomerAction {
    type: CustomerActionTypes.CUSTOMERS_LOAD_SUCCESS;
    total:number;
    customers:Array<Customer>;
}

export interface ICreateCustomerSuccessAction {
    type: CustomerActionTypes.CUSTOMER_CREATE_SUCCESS;
    customer: Customer;
}
  
export interface IUpdateCustomerSuccessAction {
    type: CustomerActionTypes.CUSTOMER_UPDATE_SUCCESS;
    customer: Customer;
}

export interface IDeleteCustomerSuccessAction {
    type: CustomerActionTypes.CUSTOMER_DELETE_SUCCESS;
    customerids: string[];
}
function loadSuccess(total:number, customers:Array<Customer>): ICustomerLoadSuccess {
    return {
        type: CustomerActionTypes.CUSTOMERS_LOAD_SUCCESS,
        total,
        customers
    }
}

function createSuccess(customer:Customer): ICreateCustomerSuccessAction {
    return {
        type: CustomerActionTypes.CUSTOMER_CREATE_SUCCESS,
        customer
    }
}

function updateSuccess(customer:Customer): IUpdateCustomerSuccessAction {
    return {
        type: CustomerActionTypes.CUSTOMER_UPDATE_SUCCESS,
        customer
    }
}

function deleteSuccess(customerids:string[]): IDeleteCustomerSuccessAction {
    return {
        type: CustomerActionTypes.CUSTOMER_DELETE_SUCCESS,
        customerids
    }
}

export type TCustomerAction = ICustomerLoadSuccess | ICreateCustomerSuccessAction | IUpdateCustomerSuccessAction | IDeleteCustomerSuccessAction | TApiCallAction;

export const actionCreators = {
    loadCustomer: (id:string): ThunkAction<Promise<void>, ApplicationState, {}, TCustomerAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(0,[]));

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await customersService.getById(accountId, id)
        .then(data => {
            dispatch(loadSuccess(1, [data]));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },
    load: (filter?:string, orderby?:string): ThunkAction<Promise<void>, ApplicationState, {}, TCustomerAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(0,[]));

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await customersService.getAll(accountId, filter, orderby)
        .then(data => {
            dispatch(loadSuccess(data.total, data.items));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },
    loadByCurrentUser: (filter?:string, orderby?:string): ThunkAction<Promise<void>, ApplicationState, {}, TCustomerAction> => async (dispatch, getState):Promise<void> => {

        dispatch(loadSuccess(0,[]));

        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        return await customersService.getByCurrentUser(accountId, filter, orderby)
        .then(data => {
            dispatch(loadSuccess(data.total, data.items));
        }).catch(error => {
            dispatch(sharedActions.apiCallError(error));
            throw error;
            });
    },    
    save: (customer:Customer) : ThunkAction<Promise<string>, ApplicationState, {}, TCustomerAction> => async (dispatch, getState):Promise<string> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        if(customer.id){
            return await customersService.update(accountId, customer)
            .then(data => {
                dispatch(updateSuccess(data));
                return data.id;
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
                });
        }else{
            return await customersService.create(accountId, customer)
            .then(data => {
                dispatch(createSuccess(data));
                return data.id;
            }).catch(error => {
                dispatch(sharedActions.apiCallError(error));
                throw error;
                });
        }
    },
    delete: (customerids:string[]) : ThunkAction<Promise<void>, ApplicationState, {}, TCustomerAction> => async (dispatch, getState):Promise<void> => {
        dispatch(sharedActions.beginApiCall());
        let accountId = getState().user.accountId;
        
        return await customersService.deleteMultiple(accountId, customerids)
        .then(data => {
            dispatch(deleteSuccess(data));
        }).catch(error => {
        dispatch(sharedActions.apiCallError(error));
            throw error;
        });

    }    
}